import Highcharts, {
  Options,
  PlotArcdiagramLevelsOptions,
  SeriesSankeyNodesOptionsObject,
  SeriesSankeyPointOptionsObject,
} from 'highcharts'
import HighchartsOrganization from 'highcharts/modules/organization'

import HighchartsComponent from './HighchartsComponent'

HighchartsOrganization(Highcharts)

interface OrganizationGraphProps {
  data: SeriesSankeyPointOptionsObject[]
  levels: PlotArcdiagramLevelsOptions[]
  nodes: SeriesSankeyNodesOptionsObject[]
  title: string
  borderColor?: string
  borderWidth?: number
  nodeWidth?: number
  colorByPoint?: boolean
  tooltipStyle?: {
    [key: string]: number | string
  }
  tooltipOutside?: boolean
  chartHeight?: number
  isInverted?: boolean
  exportFilename: string
  exportOptions?: string[]
}

const OrganizationGraph = ({
  data,
  levels,
  nodes,
  title,
  borderColor = 'black',
  borderWidth = 2,
  nodeWidth = 45,
  colorByPoint = false,
  tooltipStyle = {
    width: 320,
  },
  tooltipOutside = true,
  chartHeight = 330,
  isInverted = true,
  exportFilename,
  exportOptions = [
    'viewFullscreen',
    'downloadPNG',
    'downloadJPEG',
    'downloadPDF',
  ],
}: OrganizationGraphProps) => {
  const orgOptions: Options = {
    chart: {
      height: chartHeight,
      inverted: isInverted,
    },
    title: {
      text: title,
    },
    plotOptions: {
      series: {},
    },
    series: [
      {
        type: 'organization',
        name: '',
        keys: ['from', 'to'],
        data,
        levels,
        nodes,
        colorByPoint,
        borderColor,
        borderWidth,
        nodeWidth,
      },
    ],
    tooltip: {
      outside: tooltipOutside,
      style: tooltipStyle,
    },
    exporting: {
      allowHTML: true,
      buttons: {
        contextButton: {
          menuItems: exportOptions,
        },
      },
    },
    credits: {
      enabled: false,
    },
  }
  return (
    <HighchartsComponent
      options={orgOptions as Options}
      exportFilename={exportFilename}
    />
  )
}

export default OrganizationGraph
