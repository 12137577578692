export const meterTableHeaders = [
  {
    id: 'macAddress',
    label: 'Meter Mac',
  },
  {
    id: 'modified',
    label: 'Submitted Date',
  },
  {
    id: 'history',
    label: 'Submission History',
  },
  {
    id: 'panelNames',
    label: 'Panel Name',
  },
  {
    id: 'createdBy',
    label: 'Submitted By',
  },
  {
    id: 'isAssignedToSite',
    label: 'Assigned to Site',
  },
  {
    id: 'configAcceptedDate',
    label: 'Config Accepted',
  },
  {
    id: 'preConfigTestStatus',
    label: 'Pre-Config Tests',
  },
  {
    id: 'postConfigTestStatus',
    label: 'Post-Config Tests',
  },
  {
    id: 'evVerified',
    label: 'EV Verified',
  },
]

export const defaultMeterInstallWidths = {
  macAddress: {
    minWidth: 134,
    maxWidth: 135,
  },
  modified: {
    minWidth: 250,
    maxWidth: 250,
  },
  history: {
    minWidth: 170,
    maxWidth: 170,
  },
  panelNames: {
    minWidth: 190,
    maxWidth: 190,
  },
  createdBy: {
    minWidth: 230,
    maxWidth: 230,
  },
  configAcceptedDate: {
    minWidth: 250,
    maxWidth: 250,
  },
  isAssignedToSite: {
    minWidth: 160,
    maxWidth: 160,
  },
  evVerified: {
    minWidth: 120,
    maxWidth: 120,
  },
  preConfigTestStatus: {
    minWidth: 150,
    maxWidth: 150,
  },
  postConfigTestStatus: {
    minWidth: 150,
    maxWidth: 150,
  },
}

export const mockData = [
  {
    id: '883d3325-0411-4299-bef3-ab90f189a0e2',
    meterId: '031edbe0-3d67-4a39-97f0-969066056fb9',
    macAddress: 'FC69479A7733',
    isAssignedToSite: 'YES',
    customerName: 'Redaptive',
    siteLocation: '340 Brannan Street, Suite 104, San Francisco, CA, US, 94107',
    cellRouterInstalled: 'YES',
    evVerified: 'NO',
    userId: 'd03129a0-2b58-4258-8d3a-08b4b23068d6',
    opportunityId: 'R00029426',
    code: 'R00029426',
    isDeleted: true,
    created: '2024-10-25T09:23:01.627987',
    createdBy: 'gajendra.jangid@redaptiveinc.com',
    modified: '2024-10-25T12:42:33.793114',
    modifiedBy: 'gajendra.jangid@redaptiveinc.com',
    configAcceptedDate: '2024-10-25T09:40:28',
    panelNames: 'Panel Test1, Panel2',
    preConfigTestStatus: 'FAIL',
    postConfigTestStatus: 'FAIL',
  },
  {
    id: 'f8d49d39-c8c2-497f-9b6c-5e6d7394d903',
    meterId: '031edbe0-3d67-4a39-97f0-969066056fb9',
    macAddress: 'FC69479A7733',
    isAssignedToSite: 'YES',
    customerName: 'Redaptive',
    siteLocation: '340 Brannan Street, Suite 104, San Francisco, CA, US, 94107',
    cellRouterInstalled: 'NO',
    evVerified: 'NO',
    userId: 'd03129a0-2b58-4258-8d3a-08b4b23068d6',
    opportunityId: 'R00029426',
    code: 'R00029426',
    isDeleted: true,
    created: '2024-10-25T09:39:38.77236',
    createdBy: 'gajendra.jangid@redaptiveinc.com',
    modified: '2024-10-25T12:42:33.793114',
    modifiedBy: 'gajendra.jangid@redaptiveinc.com',
    configAcceptedDate: '2024-10-25T09:40:28',
    panelNames: 'Panel3',
    preConfigTestStatus: 'FAIL',
    postConfigTestStatus: 'FAIL',
  },
]
