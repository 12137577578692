import Tippy from '@tippyjs/react'
import { useEffect, useMemo, useState } from 'react'
import { MdDeleteOutline, MdOutlineContentCopy } from 'react-icons/all'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import {
  renderRemoveCircuitConfirm,
  renderRemoveSwitchConfirm,
  renderResetCircuitBreakersConfirm,
  renderResetSwitchesConfirm,
  UNSAVED_CHANGES_MESSAGE,
  UNSAVED_PANEL_MESSAGE,
} from './Modals'
import PanelCircuitsTable from './PanelCircuitsTable'
import type { FTBuildingSystemSummary } from '../../ducks/buildingSystems'
import type { FTPanelCircuit } from '../../ducks/panelCircuits'
import { MeterIconWrapperStyled } from '../../pages/PanelConfiguration/style'
import {
  ActionIconWrapperStyled,
  ActionsIconStyled,
  ActionsPopupStyled,
  ActionStyled,
} from '../../pages/ProposalOperations/ProposalsEngine/BatchAnalysis'
import type { FTFormFieldEvent, FTWithFormik } from '../../types'
import { getValueFromEvent, makeListSelectorItem } from '../../utils'
import {
  columnWidths,
  getCreatedCircuits,
  MULTI_POLE_BREAKERS,
  MULTI_POLE_SAME_BREAKERS,
  replicateCircuits,
  SINGLE_POLE_BREAKERS,
  THREE_POLE_BREAKERS,
  TWO_POLE_BREAKERS,
} from '../../utils/panelConfiguration'
import {
  AddCircleOutlineIconStyled,
  AddPanelActionButtonStyled,
} from '../PanelsTab'
import { FullScreenBgAlphaLayer } from '../CommentsPopup'
import FormButtons, { StyledButtons } from '../FormButtons'
import HistoryIcon from '../Icons/HistoryIcon'
import MeteredCircuit from '../Icons/svg/MeteredCircuit'
import UnMeteredCircuit from '../Icons/svg/UnMeteredCircuit'
import Input from '../Input'
import IonIcon from '../IonIcon'
import ListSelector from '../ListSelector'
import { findArrayType, getNumericPart } from '../ModalPanelForm3/utils'
import UnsavedChanges from '../UnsavedChanges'

const StyledListSelector = styled(ListSelector)`
  width: 80px;
  height: 36px;

  .Select__input,
  .Select__multi-value-wrapper {
    height: 100%;
  }

  .Select__option,
  .Select__value-container {
    align-items: center;
    display: flex;
  }

  .Select__placeholder {
    width: 100%;
  }

  .Select__menu-outer {
    top: auto;
  }

  .Select--is-disabled {
    opacity: 0.5;
  }

  .ListSelector-Dropdown {
    width: 70px;
  }
`
const AmperageListSelector = styled(StyledListSelector)`
  .ListSelector-Dropdown {
    width: 90px;
  }
`
const BreakerTypeListSelector = styled(StyledListSelector)`
  .ListSelector-Dropdown {
    width: 150px;
  }
`
const BuildingSystemListSelector = styled(StyledListSelector)`
  .ListSelector-Dropdown {
    width: 180px;
  }
`
const FormContainterStyled = styled.div`
  ${StyledButtons} {
    padding-top: 50px;
  }

  input:disabled {
    opacity: 0.5;
  }
  input::placeholder {
    opacity: 0.3;
  }
`
const ResetActionStyled = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 20px;
  top: 0px;
`
const DeleteActionStyled = styled(ActionStyled)`
  color: #b71111;
`
const HistoryIconStyed = styled(HistoryIcon)`
  width: 30px;
`
const ActionsCellWrapperStyled = styled.div`
  position: relative;
`

const CellText = styled.div`
  opacity: 0.5;
  overflow: hidden;
  text-overflow: ellipsis;
`

type FTProps = {
  circuitsById: Record<string, FTPanelCircuit>
  circuitsLoading: string
  buildingSystems: Array<FTBuildingSystemSummary>
  buildingSystemsById: Record<string, FTBuildingSystemSummary>
  inCreateMode: boolean
  isNumbered: boolean
  isUpdateSuccess: boolean
} & FTWithFormik
type FTCell = {
  // eslint-disable-next-line react/no-unused-prop-types
  original: FTPanelCircuit
  value: string
  // eslint-disable-next-line react/no-unused-prop-types
  index: number
}

const Form = (props: FTProps) => {
  const {
    values,
    circuitsLoading,
    handleSubmit,
    inCreateMode,
    isNumbered,
    isUpdateSuccess,
    goBack,
    status: {
      validCtTypes,
      validBreakerTypes,
      validAmperageValues,
      phaseOptions,
      breakerNumbers,
    },
    circuitsOrder,
    buildingSystems,
    buildingSystemsById,
    dirty,
    initialValues,
    panels,
    resetForm,
    showConfirmModal,
  } = props
  const [actionsPopupId, setActionsPopupId] = useState('')
  const { newCircuitIds, circuitsById, deletedCircuitIds } = values

  // parent: [...children]
  const [leftGangedCircuitsMap, setLeftGangedCircuitsMap] = useState(new Map())
  const [rightGangedCircuitsMap, setRightGangedCircuitsMap] = useState(
    new Map(),
  )
  // Circuits which are grouped together
  const groupedCircuitsArray = useMemo(
    () =>
      Array.from([...leftGangedCircuitsMap, ...rightGangedCircuitsMap]).map(
        (entry) => [entry[0], ...entry[1]],
      ),
    [leftGangedCircuitsMap, rightGangedCircuitsMap],
  )
  // Circuits which should be merged/disabled. These are groupedCircuits without the heads
  const gangedCircuitChildren = useMemo(
    () =>
      new Set(
        Array.from([...leftGangedCircuitsMap, ...rightGangedCircuitsMap])
          .map((entry) => entry[1])
          .reduce((prev, curr) => [...prev, ...curr], []),
      ),
    [leftGangedCircuitsMap, rightGangedCircuitsMap],
  )

  // Get children circuits(if any) for a given circuitId
  const getGangedChildren = (parentId) =>
    leftGangedCircuitsMap.get(parentId) || rightGangedCircuitsMap.get(parentId)

  // Heads of the ganged circuits
  const gangedCircuitHeads = useMemo(
    () =>
      new Set(
        Array.from([...leftGangedCircuitsMap, ...rightGangedCircuitsMap])
          .map((entry) => entry[0])
          .reduce((prev, curr) => [...prev, curr], []),
      ),
    [leftGangedCircuitsMap, rightGangedCircuitsMap],
  )
  const circuits = Object.values(circuitsById)
    .filter((circuit) => !newCircuitIds.includes(circuit.id))
    .sort((a, b) => circuitsOrder.indexOf(a.id) - circuitsOrder.indexOf(b.id))
    .filter((circuit) => !deletedCircuitIds.includes(circuit.id))
    .concat(newCircuitIds.map((id) => circuitsById[id]))
  let leftCircuits = circuits.filter(
    (circuit) => circuit.sideBreakerOrder === 'LEFT',
  )
  let rightCircuits = circuits.filter(
    (circuit) => circuit.sideBreakerOrder === 'RIGHT',
  )

  if (leftCircuits.length + rightCircuits.length < circuits.length) {
    const leftSideType = findArrayType(
      leftCircuits.map((circuit) => getNumericPart(circuit.breakerNumber)!),
    )
    const rightSideType = findArrayType(
      rightCircuits.map((circuit) => getNumericPart(circuit.breakerNumber)!),
    )

    if (leftSideType === 'EVEN' || rightSideType === 'ODD') {
      leftCircuits = circuits.filter(
        (circuit) => getNumericPart(circuit.breakerNumber)! === 0,
      )
      rightCircuits = circuits.filter(
        (circuit) => getNumericPart(circuit.breakerNumber)! % 2 === 1,
      )
    }
    if (leftSideType === 'ODD' || rightSideType === 'EVEN') {
      leftCircuits = circuits.filter(
        (circuit) => getNumericPart(circuit.breakerNumber)! % 2 === 1,
      )
      rightCircuits = circuits.filter(
        (circuit) => getNumericPart(circuit.breakerNumber)! % 2 === 0,
      )
    }

    if (leftSideType === 'CONSECUTIVE') {
      // half the circuits to left and half to right
      const halfLength = Math.floor(circuits.length / 2)
      leftCircuits = circuits.slice(0, halfLength)
      rightCircuits = circuits.slice(halfLength)
    }
  }

  // TODO: Make more assumptions here
  // If the circuits don't have the mandatory fields
  if (isNumbered) {
    if (
      circuits.length > 0 &&
      leftCircuits.length === 0 &&
      rightCircuits.length === 0
    ) {
      leftCircuits = circuits.filter(
        (circuit) => getNumericPart(circuit.breakerNumber)! % 2 === 1,
      )
      rightCircuits = circuits.filter(
        (circuit) => getNumericPart(circuit.breakerNumber)! % 2 === 0,
      )
    }
  } else {
    rightCircuits = circuits
  }
  const meteredCircuits = circuits
    .filter((circuit) => circuit.isMetered)
    .map((circuit) => circuit.id)
  // TODO: Remove nested ternary
  // eslint-disable-next-line no-nested-ternary
  const breakerNumbersPattern =
    leftCircuits.every((circuit) => circuit.breakerNumber % 2 === 0) ?
      {
        LEFT: 'EVEN',
        RIGHT: 'ODD',
      }
    : leftCircuits.every((circuit) => circuit.breakerNumber % 2 === 1) ?
      {
        LEFT: 'ODD',
        RIGHT: 'EVEN',
      }
    : {
        LEFT: 'CONSECUTIVE',
        RIGHT: 'CONSECUTIVE',
      }

  //  Will identify and update the gangedCircuitChildren state
  const markGangedCircuits = (side) => {
    const circuitsArray = side === 'LEFT' ? leftCircuits : rightCircuits
    const newGangedCircuitsMap = new Map()

    for (let i = 0; i < circuitsArray.length - 1; i += 1) {
      if (TWO_POLE_BREAKERS.includes(circuitsArray[i].breakerType)) {
        newGangedCircuitsMap.set(circuitsArray[i].id, [circuitsArray[i + 1].id])
        i += 1
      }
    }

    for (let i = 0; i < circuitsArray.length - 2; i += 1) {
      if (THREE_POLE_BREAKERS.includes(circuitsArray[i].breakerType)) {
        newGangedCircuitsMap.set(circuitsArray[i].id, [
          circuitsArray[i + 1].id,
          circuitsArray[i + 2].id,
        ])
        i += 2
      }
    }

    if (side === 'LEFT') {
      setLeftGangedCircuitsMap(new Map(newGangedCircuitsMap))
    } else {
      setRightGangedCircuitsMap(new Map(newGangedCircuitsMap))
    }
  }

  useEffect(() => {
    markGangedCircuits('LEFT')
    markGangedCircuits('RIGHT')
  }, [values])

  const handleCreateMockCircuits = () => {
    const { setFieldValue } = props
    const circuitsObject = getCreatedCircuits(
      {
        numberOfCircuits: 2,
        leftPanelOrder: 'ODD',
        phasingPattern: 'TOP_TO_BOTTOM',
      },
      isNumbered,
    )
    setFieldValue('newCircuitIds', Object.keys(circuitsObject))
    setFieldValue('circuitsById', circuitsObject)
  }

  const handleReplicateCircuit = (circuitId) => {
    const { setFieldValue } = props
    const {
      id,
      breakerNumber,
      isCreated,
      isMetered,
      phase,
      breakerType,
      sideBreakerOrder,
      phaseGroupSummary,
      ...otherFields
    } = circuitsById[circuitId]

    const sideCircuits =
      sideBreakerOrder === 'LEFT' ? leftCircuits : rightCircuits
    const maxBreakerNumber = Number(
      sideCircuits[sideCircuits.length - 1].breakerNumber?.split(/\D/).join(''),
    )
    const breakerNumberDiff =
      ['ODD', 'EVEN'].includes(breakerNumbersPattern[sideBreakerOrder]) ? 2 : 1
    const newCircuits = replicateCircuits(
      breakerType,
      maxBreakerNumber,
      breakerNumberDiff,
      {
        sideBreakerOrder,
        ...otherFields,
        breakerNumber,
      },
      isNumbered,
    )
    setFieldValue('newCircuitIds', [
      ...newCircuitIds,
      ...Object.keys(newCircuits),
    ])
    setFieldValue('circuitsById', { ...circuitsById, ...newCircuits })
    setActionsPopupId('')
  }

  const handleDeleteCircuit = (circuitId) => {
    showConfirmModal({
      modalWidth: '418px',
      onPrimaryAction: undefined,
      onSecondaryAction: () => {
        const { setFieldValue } = props
        let toBeDeleted = [circuitId]

        if (gangedCircuitHeads.has(circuitId)) {
          toBeDeleted = [
            circuitId,
            ...(leftGangedCircuitsMap.get(circuitId) ||
              rightGangedCircuitsMap.get(circuitId)),
          ]
        }

        if (circuitsById[toBeDeleted[0]].isCreated) {
          setFieldValue(
            'newCircuitIds',
            newCircuitIds.filter((id) => !toBeDeleted.includes(id)),
          )
        } else {
          setFieldValue('deletedCircuitIds', [
            ...deletedCircuitIds,
            ...toBeDeleted,
          ])
        }

        const newCircuitsById = Object.keys(circuitsById)
          .filter((item) => !toBeDeleted.includes(item))
          .reduce((prev, curr) => ({ ...prev, [curr]: circuitsById[curr] }), {})
        setFieldValue('circuitsById', newCircuitsById)
        setActionsPopupId('')
      },
      primaryActionText: 'No',
      renderBody: () =>
        isNumbered ? renderRemoveCircuitConfirm() : renderRemoveSwitchConfirm(),
      secondaryActionText: 'Yes',
    })
  }

  const getGroupedCircuitIds = (circuitId) =>
    groupedCircuitsArray[
      groupedCircuitsArray.findIndex((group) => group.includes(circuitId))
    ]

  // Gives the possible breaker types for the given circuit based on the breaker configuration
  const getValidBreakerTypes = (
    index: number,
    sideBreakerOrder: 'LEFT' | 'RIGHT',
  ) => {
    const sideCircuits =
      sideBreakerOrder === 'LEFT' ? leftCircuits : rightCircuits
    const remainingCircuits = sideCircuits.length - index - 1
    let possibleBreakerTypes = validBreakerTypes
    const nextNextCircuit =
      index + 2 < sideCircuits.length ?
        circuitsById[sideCircuits[index + 2].id]
      : null
    const nextCircuit =
      index + 1 < sideCircuits.length ?
        circuitsById[sideCircuits[index + 1].id]
      : null

    // Filter two pole options if the +1 index is a Multi pole breaker
    if (
      nextCircuit &&
      MULTI_POLE_BREAKERS.includes(nextCircuit.breakerType) &&
      gangedCircuitHeads.has(nextCircuit.id)
    ) {
      // If the next breaker is a two pole, filter two pole breakers. The user can still select a three pole
      if (TWO_POLE_BREAKERS.includes(nextCircuit.breakerType)) {
        possibleBreakerTypes = possibleBreakerTypes.filter(
          (item) => !TWO_POLE_BREAKERS.includes(item.id),
        )
      } else {
        // If the next breaker is a three pole, filter both two and three pole breakers
        possibleBreakerTypes = possibleBreakerTypes.filter(
          (item) => !MULTI_POLE_BREAKERS.includes(item.id),
        )
      }
    }

    // Filter three pole options if the +2 index is a Multi pole breaker
    if (
      nextNextCircuit &&
      MULTI_POLE_BREAKERS.includes(nextNextCircuit.breakerType) &&
      gangedCircuitHeads.has(nextNextCircuit.id)
    ) {
      possibleBreakerTypes = possibleBreakerTypes.filter(
        (item) => !THREE_POLE_BREAKERS.includes(item.id),
      )
    }

    if (remainingCircuits < 2) {
      possibleBreakerTypes = validBreakerTypes.filter(
        (item) => !THREE_POLE_BREAKERS.includes(item.id),
      )

      if (remainingCircuits < 1) {
        possibleBreakerTypes = possibleBreakerTypes.filter(
          (item) => !TWO_POLE_BREAKERS.includes(item.id),
        )
      }
    }

    return possibleBreakerTypes
  }

  const ActionsPopup = ({
    open,
    id,
    sideBreakerOrder,
    index,
  }: {
    open: boolean
    id: string
    sideBreakerOrder: 'LEFT' | 'RIGHT'
    index: number
  }) => {
    const allowCircuitDeletion =
      sideBreakerOrder === 'LEFT' ?
        leftCircuits.length > 1
      : rightCircuits.length > 1
    const numberOfCircuits =
      sideBreakerOrder === 'LEFT' ? leftCircuits.length : rightCircuits.length
    const { breakerType } = circuitsById[id] || {}
    const isTwoPoleBreaker =
      breakerType === 'TWO_POLE_SAME_BREAKER' ||
      breakerType === 'TWO_POLE_DIFFERENT_BREAKER'
    const isThreePoleBreaker =
      breakerType === 'THREE_POLE_SAME_BREAKER' ||
      breakerType === 'THREE_POLE_DIFFERENT_BREAKER'

    const notAllowRemovalOnBreakerType =
      isNumbered ?
        index === 0 &&
        ((numberOfCircuits < 3 && (isTwoPoleBreaker || isThreePoleBreaker)) ||
          (numberOfCircuits < 4 && isThreePoleBreaker))
      : false
    return (
      <ActionsPopupStyled open={open}>
        <FullScreenBgAlphaLayer
          onClick={() => {
            setActionsPopupId('')
          }}
        />
        {/* <ActionStyled onClick={() => { */}
        {/*   // TODO: Show Meter Validation Modal here once it's required */}
        {/* }} */}
        {/* > */}
        {/*   <ActionIconWrapperStyled> */}
        {/*     <CheckCircleOutlineIcon fontSize="24px" /> */}
        {/*   </ActionIconWrapperStyled> */}
        {/*   Assign Meter */}
        {/* </ActionStyled> */}
        <ActionStyled onClick={() => handleReplicateCircuit(id)}>
          <ActionIconWrapperStyled>
            <MdOutlineContentCopy fontSize='24px' />
          </ActionIconWrapperStyled>
          {isNumbered ? 'Replicate Circuit' : 'Replicate Switch'}
        </ActionStyled>
        {!meteredCircuits.includes(id) &&
          allowCircuitDeletion &&
          !notAllowRemovalOnBreakerType &&
          (isNumbered ? breakerType : true) && (
            <DeleteActionStyled onClick={() => handleDeleteCircuit(id)}>
              <ActionIconWrapperStyled>
                <MdDeleteOutline fontSize='24px' />
              </ActionIconWrapperStyled>
              {isNumbered ? 'Remove Circuit' : 'Remove Switch'}
            </DeleteActionStyled>
          )}
      </ActionsPopupStyled>
    )
  }

  const getActionsCell = (cellProps) => {
    const {
      original: { id, sideBreakerOrder },
      row,
    } = cellProps
    if (gangedCircuitChildren.has(id)) return null
    // TODO: Don't add 'Assign Meter' functionality to Empty slot
    return (
      <ActionsCellWrapperStyled>
        <ActionsIconStyled
          onClick={() => {
            setActionsPopupId(actionsPopupId !== id ? id : '')
          }}
        >
          <IonIcon fontSize='24px' iconClass='ion-android-more-vertical' />
        </ActionsIconStyled>
        <ActionsPopup
          sideBreakerOrder={sideBreakerOrder}
          id={id}
          index={row._index}
          open={actionsPopupId === id}
        />
      </ActionsCellWrapperStyled>
    )
  }

  const setBreakerInfo = (circuitId, breakerNumber, breakerType = false) => {
    const { setFieldValue } = props

    if (breakerNumber) {
      setFieldValue(`circuitsById.${circuitId}.breakerNumber`, breakerNumber)
    }

    if (breakerType) {
      setFieldValue(`circuitsById.${circuitId}.breakerType`, breakerType)
    }
  }

  // TODO: Ensure that we only allow right combinations as the PhaseGroup relies on these
  const onUpdateBreakerNumber =
    (circuitId) => (event: FTFormFieldEvent, breakerType) => {
      const value = getValueFromEvent(event)

      if (MULTI_POLE_SAME_BREAKERS.includes(breakerType)) {
        const groupedCircuits = getGroupedCircuitIds(circuitId)
        groupedCircuits?.forEach((id) => setBreakerInfo(id, value))
      } else {
        setBreakerInfo(circuitId, value)
      }
    }

  const onUpdateField =
    (circuitId, field: string) => (event: FTFormFieldEvent) => {
      const { setFieldValue } = props
      setFieldValue(
        `circuitsById.${circuitId}.${field}`,
        getValueFromEvent(event),
      )
      const children = getGangedChildren(circuitId)

      if (children && field !== 'phase') {
        children.forEach((id) =>
          setFieldValue(
            `circuitsById.${id}.${field}`,
            getValueFromEvent(event),
          ),
        )
      }
    }

  const onUpdateBuildingSystemId = (circuitId) => (event: FTFormFieldEvent) => {
    const { setFieldValue } = props
    setFieldValue(
      `circuitsById.${circuitId}.buildingSystemId`,
      getValueFromEvent(event),
    )
    const children = getGangedChildren(circuitId)

    if (children) {
      children.forEach((id) =>
        setFieldValue(
          `circuitsById.${id}.buildingSystemId`,
          getValueFromEvent(event),
        ),
      )
    }
  }

  const getInitialCircuitValues = (circuitId) =>
    initialValues.circuitsById[circuitId] ?
      initialValues.circuitsById[circuitId]
    : {}

  const getInitialCircuitValue = (circuitId, field) =>
    initialValues.circuitsById[circuitId] ?
      initialValues.circuitsById[circuitId][field]
    : undefined

  const getCircuitValues = (circuitId) =>
    circuitsById[circuitId] ? circuitsById[circuitId] : {}

  const setBreakerTypeDependentFields = (
    circuitId: string,
    circuitValues: FTPanelCircuit,
    breakerType?: string,
    updateBreakerNumber: boolean = false,
  ) => {
    const { setFieldValue } = props
    const {
      description = '',
      buildingArea = '',
      buildingSystemId = '',
      equipmentName = '',
      amperage = '',
      CTSizeExpected = '',
      breakerNumber,
    } = circuitValues
    setFieldValue(`circuitsById.${circuitId}.description`, description)
    setFieldValue(
      `circuitsById.${circuitId}.buildingSystemId`,
      buildingSystemId,
    )
    setFieldValue(`circuitsById.${circuitId}.equipmentName`, equipmentName)
    setFieldValue(`circuitsById.${circuitId}.buildingArea`, buildingArea)
    setFieldValue(`circuitsById.${circuitId}.amperage`, amperage)
    setFieldValue(`circuitsById.${circuitId}.CTSizeExpected`, CTSizeExpected)
    setBreakerInfo(
      circuitId,
      updateBreakerNumber ? breakerNumber : null,
      breakerType,
    )
  }

  const onUpdateBreakerType =
    (circuitId) =>
    (event: FTFormFieldEvent, circuitIndex, sideBreakerOrder) => {
      const currBreakerType = circuitsById[circuitId].breakerType
      const newBreakerType = getValueFromEvent(event)
      if (!newBreakerType || newBreakerType === currBreakerType) return
      const { setFieldValue } = props
      const sideCircuits =
        sideBreakerOrder === 'LEFT' ? leftCircuits : rightCircuits
      const nextCircuitId = sideCircuits[circuitIndex + 1]?.id
      const nextNextCircuitId = sideCircuits[circuitIndex + 2]?.id
      const currValues = getCircuitValues(circuitId)
      // Set current row breaker type and proceed to next steps
      setFieldValue(`circuitsById.${circuitId}.breakerType`, newBreakerType)

      // To understand the following switch cases, Refer to
      // the functional requirements in https://redaptiveinc.atlassian.net/browse/GRN-802?focusedCommentId=45108

      switch (currBreakerType) {
        case 'EMPTY_SLOT':
          if (TWO_POLE_BREAKERS.includes(newBreakerType)) {
            if (newBreakerType === 'TWO_POLE_SAME_BREAKER') {
              setBreakerInfo(nextCircuitId, currValues.breakerNumber)
            }

            setBreakerTypeDependentFields(nextCircuitId, {}, newBreakerType)
          } else if (THREE_POLE_BREAKERS.includes(newBreakerType)) {
            if (newBreakerType === 'THREE_POLE_SAME_BREAKER') {
              setBreakerInfo(nextCircuitId, currValues.breakerNumber)
              setBreakerInfo(nextNextCircuitId, currValues.breakerNumber)
            }

            setBreakerTypeDependentFields(nextCircuitId, {}, newBreakerType)
            setBreakerTypeDependentFields(nextNextCircuitId, {}, newBreakerType)
          }

          break

        case 'SINGLE_POLE_BREAKER':
        case 'TANDEM_BREAKER':
        case 'DOUBLE_TAP_BREAKER':
        case '':
          if (newBreakerType === 'EMPTY_SLOT') {
            setBreakerTypeDependentFields(circuitId, {})
          } else if (TWO_POLE_BREAKERS.includes(newBreakerType)) {
            setBreakerTypeDependentFields(
              nextCircuitId,
              currValues,
              newBreakerType,
              newBreakerType === 'TWO_POLE_SAME_BREAKER',
            )
          } else if (THREE_POLE_BREAKERS.includes(newBreakerType)) {
            setBreakerTypeDependentFields(
              nextCircuitId,
              currValues,
              newBreakerType,
              newBreakerType === 'THREE_POLE_SAME_BREAKER',
            )
            setBreakerTypeDependentFields(
              nextNextCircuitId,
              currValues,
              newBreakerType,
              newBreakerType === 'THREE_POLE_SAME_BREAKER',
            )
          }

          break

        case 'TWO_POLE_SAME_BREAKER':
          if (newBreakerType === 'EMPTY_SLOT') {
            setBreakerTypeDependentFields(circuitId, {})
            setBreakerTypeDependentFields(
              nextCircuitId,
              getInitialCircuitValues(nextCircuitId),
              'SINGLE_POLE_BREAKER',
              true,
            )
          } else if (SINGLE_POLE_BREAKERS.includes(newBreakerType)) {
            setBreakerTypeDependentFields(
              nextCircuitId,
              getInitialCircuitValues(nextCircuitId),
              'SINGLE_POLE_BREAKER',
              true,
            )
          } else if (THREE_POLE_BREAKERS.includes(newBreakerType)) {
            setBreakerInfo(
              nextCircuitId,
              newBreakerType === 'THREE_POLE_DIFFERENT_BREAKER' ?
                getInitialCircuitValue(nextCircuitId, 'breakerNumber')
              : '',
              newBreakerType,
            )
            setBreakerTypeDependentFields(
              nextNextCircuitId,
              currValues,
              newBreakerType,
              newBreakerType === 'THREE_POLE_SAME_BREAKER',
            )
          } else {
            // Two Pole Different Breaker
            setBreakerInfo(
              nextCircuitId,
              getInitialCircuitValue(nextCircuitId, 'breakerNumber'),
              newBreakerType,
            )
          }

          break

        case 'TWO_POLE_DIFFERENT_BREAKER':
          if (newBreakerType === 'EMPTY_SLOT') {
            setBreakerTypeDependentFields(circuitId, {})
            setBreakerTypeDependentFields(
              nextCircuitId,
              getInitialCircuitValues(nextCircuitId),
              'SINGLE_POLE_BREAKER',
            )
          } else if (SINGLE_POLE_BREAKERS.includes(newBreakerType)) {
            setBreakerTypeDependentFields(
              nextCircuitId,
              getInitialCircuitValues(nextCircuitId),
              'SINGLE_POLE_BREAKER',
            )
          } else if (THREE_POLE_BREAKERS.includes(newBreakerType)) {
            setBreakerInfo(
              nextCircuitId,
              newBreakerType === 'THREE_POLE_SAME_BREAKER' ?
                currValues.breakerNumber
              : '',
              newBreakerType,
            )
            setBreakerTypeDependentFields(
              nextNextCircuitId,
              currValues,
              newBreakerType,
              newBreakerType === 'THREE_POLE_SAME_BREAKER',
            )
          } else {
            // Two Pole Same Breaker
            setBreakerInfo(
              nextCircuitId,
              currValues.breakerNumber,
              newBreakerType,
            )
          }

          break

        case 'THREE_POLE_SAME_BREAKER':
          if (newBreakerType === 'EMPTY_SLOT') {
            setBreakerTypeDependentFields(circuitId, {})
            setBreakerTypeDependentFields(
              nextCircuitId,
              getInitialCircuitValues(nextCircuitId),
              'SINGLE_POLE_BREAKER',
              true,
            )
            setBreakerTypeDependentFields(
              nextNextCircuitId,
              getInitialCircuitValues(nextNextCircuitId),
              'SINGLE_POLE_BREAKER',
              true,
            )
          } else if (SINGLE_POLE_BREAKERS.includes(newBreakerType)) {
            setBreakerTypeDependentFields(
              nextCircuitId,
              getInitialCircuitValues(nextCircuitId),
              'SINGLE_POLE_BREAKER',
              true,
            )
            setBreakerTypeDependentFields(
              nextNextCircuitId,
              getInitialCircuitValues(nextNextCircuitId),
              'SINGLE_POLE_BREAKER',
              true,
            )
          } else if (TWO_POLE_BREAKERS.includes(newBreakerType)) {
            setBreakerInfo(
              nextCircuitId,
              newBreakerType === 'TWO_POLE_DIFFERENT_BREAKER' ?
                getInitialCircuitValues(nextCircuitId).breakerNumber
              : '',
              newBreakerType,
            )
            setBreakerTypeDependentFields(
              nextNextCircuitId,
              getInitialCircuitValues(nextNextCircuitId),
              'SINGLE_POLE_BREAKER',
              true,
            )
          } else {
            // Three Pole Different Breaker
            setBreakerInfo(
              nextCircuitId,
              getInitialCircuitValues(nextCircuitId).breakerNumber,
              newBreakerType,
            )
            setBreakerInfo(
              nextNextCircuitId,
              getInitialCircuitValues(nextNextCircuitId).breakerNumber,
              newBreakerType,
            )
          }

          break

        case 'THREE_POLE_DIFFERENT_BREAKER':
          if (newBreakerType === 'EMPTY_SLOT') {
            setBreakerTypeDependentFields(circuitId, {})
            setBreakerTypeDependentFields(
              nextCircuitId,
              getInitialCircuitValues(nextCircuitId),
              'SINGLE_POLE_BREAKER',
            )
            setBreakerTypeDependentFields(
              nextNextCircuitId,
              getInitialCircuitValues(nextNextCircuitId),
              'SINGLE_POLE_BREAKER',
            )
          } else if (SINGLE_POLE_BREAKERS.includes(newBreakerType)) {
            setBreakerTypeDependentFields(
              nextCircuitId,
              getInitialCircuitValues(nextCircuitId),
              'SINGLE_POLE_BREAKER',
            )
            setBreakerTypeDependentFields(
              nextNextCircuitId,
              getInitialCircuitValues(nextNextCircuitId),
              'SINGLE_POLE_BREAKER',
            )
          } else if (TWO_POLE_BREAKERS.includes(newBreakerType)) {
            setBreakerInfo(
              nextCircuitId,
              newBreakerType === 'TWO_POLE_SAME_BREAKER' ?
                currValues.breakerNumber
              : '',
              newBreakerType,
            )
            setBreakerTypeDependentFields(
              nextNextCircuitId,
              getInitialCircuitValues(nextNextCircuitId),
              'SINGLE_POLE_BREAKER',
            )
          } else {
            // Three Pole Same Breaker
            setBreakerInfo(
              nextCircuitId,
              currValues.breakerNumber,
              newBreakerType,
            )
            setBreakerInfo(
              nextNextCircuitId,
              currValues.breakerNumber,
              newBreakerType,
            )
          }

          break

        default:
      }
    }

  const onUpdateTextField = (circuitId, field) => (event) => {
    const { setFieldValue } = props
    const { value } = event.target
    setFieldValue(`circuitsById.${circuitId}.${field}`, value)
    const children = getGangedChildren(circuitId)

    if (children) {
      children.forEach((id) =>
        setFieldValue(`circuitsById.${id}.${field}`, value),
      )
    }
  }

  const getUpdateValueHandlers = () => {
    const {
      initialValues: { circuitsById: initialCircuitsById },
    } = props
    const updateValueHandlers = {
      circuits: {},
    }
    Object.keys(initialCircuitsById).forEach((circuitId) => {
      updateValueHandlers.circuits[circuitId] = {
        breakerNumber: onUpdateBreakerNumber(circuitId),
        phase: onUpdateField(circuitId, 'phase'),
        breakerType: onUpdateBreakerType(circuitId),
        panelFeedId: onUpdateField(circuitId, 'panelFeedId'),
        switchName: onUpdateTextField(circuitId, 'breakerNumber'),
        // Handlers below this line will update child circuits (if any)
        description: onUpdateTextField(circuitId, 'description'),
        buildingSystemId: onUpdateBuildingSystemId(circuitId),
        equipmentName: onUpdateTextField(circuitId, 'equipmentName'),
        buildingArea: onUpdateTextField(circuitId, 'buildingArea'),
        amperage: onUpdateField(circuitId, 'amperage'),
        CTSize: onUpdateField(circuitId, 'CTSizeExpected'),
      }
    })
    // Add handlers For circuits replicated
    newCircuitIds.forEach((circuitId) => {
      updateValueHandlers.circuits[circuitId] = {
        breakerNumber: onUpdateBreakerNumber(circuitId),
        phase: onUpdateField(circuitId, 'phase'),
        breakerType: onUpdateBreakerType(circuitId),
        panelFeedId: onUpdateField(circuitId, 'panelFeedId'),
        switchName: onUpdateTextField(circuitId, 'breakerNumber'),
        // Handlers below this line will update child circuits (if any)
        description: onUpdateTextField(circuitId, 'description'),
        buildingSystemId: onUpdateBuildingSystemId(circuitId),
        equipmentName: onUpdateTextField(circuitId, 'equipmentName'),
        buildingArea: onUpdateTextField(circuitId, 'buildingArea'),
        amperage: onUpdateField(circuitId, 'amperage'),
        CTSize: onUpdateField(circuitId, 'CTSizeExpected'),
      }
    })
    return updateValueHandlers
  }

  const circuitFieldIsEdited = (circuitId, field) => {
    const initial = getInitialCircuitValue(circuitId, field)
    const current =
      circuitsById[circuitId] ? circuitsById[circuitId][field] : undefined
    return initial !== current
  }

  const getColWidthForBreakerNumber = () =>
    isNumbered ?
      { ...columnWidths.breakerNumber }
    : { ...columnWidths.switchName }

  let editableColumns = [
    {
      accessor: 'isMetered',
      Header: 'Metered',
      Cell: ({ original: { macAddress, meterId }, value }: FTCell) => (
        <MeterIconWrapperStyled>
          {value ?
            <Tippy content={macAddress} disabled={!macAddress}>
              <Link to={`../.../../../meters/${meterId}/configuration`}>
                <MeteredCircuit />
              </Link>
            </Tippy>
          : <UnMeteredCircuit />}
        </MeterIconWrapperStyled>
      ),
      ...columnWidths.isMetered,
    },
    {
      accessor: 'breakerNumber',
      Header: isNumbered ? 'Breaker #' : 'Switch Name',
      Cell: ({ original: { id, breakerType }, value }: FTCell) => (
        <>
          {isNumbered ?
            <StyledListSelector
              items={breakerNumbers}
              unsettable={false}
              edited={circuitFieldIsEdited(id, 'breakerNumber')}
              editDotRight='0px'
              notSetItemText='--'
              notSetLabelText='--'
              notSetItemValue=''
              selectedItem={makeListSelectorItem(value)}
              updateValue={(e) =>
                getUpdateValueHandlers().circuits[id].breakerNumber(
                  e,
                  breakerType,
                )
              }
              tabIndex={0}
              hideEditDotOnHover={false}
              disabled={meteredCircuits.includes(id)}
              menuPortalTarget
            />
          : <Input
              alwaysDraw
              disabled={
                meteredCircuits.includes(id) || gangedCircuitChildren.has(id)
              }
              editDotLeft='170px'
              type='text'
              hideEditDotOnHover={false}
              key={`${id}.breakerNumber`}
              name={`circuitsById.${id}.breakerNumber`}
              edited={circuitFieldIsEdited(id, 'breakerNumber')}
              value={value}
              placeholder='--'
              onChange={getUpdateValueHandlers().circuits[id].switchName}
            />
          }
        </>
      ),
      ...getColWidthForBreakerNumber(),
    },
    {
      accessor: 'phase',
      Header: 'Phase',
      Cell: ({ original: { id }, value }: FTCell) => (
        <StyledListSelector
          items={phaseOptions}
          edited={circuitFieldIsEdited(id, 'phase')}
          editDotRight='0px'
          unsettable
          notSetItemText='--'
          notSetLabelText='--'
          notSetItemValue=''
          selectedItem={makeListSelectorItem(value)}
          updateValue={getUpdateValueHandlers().circuits[id].phase}
          tabIndex={0}
          disabled={meteredCircuits.includes(id)}
          menuPortalTarget
        />
      ),
      ...columnWidths.phase,
    },
    {
      accessor: 'breakerType',
      Header: 'Breaker Type',
      Cell: ({ original: { id, sideBreakerOrder }, value, index }: FTCell) => (
        <BreakerTypeListSelector
          items={getValidBreakerTypes(index, sideBreakerOrder)}
          edited={circuitFieldIsEdited(id, 'breakerType')}
          editDotRight='-80px' // Remove the notSet option once a value is selected
          unsettable={!value}
          notSetItemText='--'
          notSetLabelText='--'
          notSetItemValue=''
          selectedItem={makeListSelectorItem(value)}
          updateValue={(e) =>
            getUpdateValueHandlers().circuits[id].breakerType(
              e,
              index,
              sideBreakerOrder,
            )
          }
          tabIndex={0}
          hideEditDotOnHover={false}
          disabled={
            meteredCircuits.includes(id) || gangedCircuitChildren.has(id)
          }
          menuPortalTarget
        />
      ),
      ...columnWidths.breakerType,
    },
    {
      accessor: 'phaseGroupSummary',
      Header: 'Phase Group',
      Cell: ({ value }: FTCell) => (
        <Tippy content={value?.name}>
          <CellText>{value?.name || '--'}</CellText>
        </Tippy>
      ),
      ...columnWidths.phaseGroup,
    },
    {
      accessor: 'panelFeedId',
      Header: 'Panel Feed To',
      Cell: (val: FTCell) => {
        const {
          original: { id, panelFeedId },
          value,
        } = val
        return (
          <BuildingSystemListSelector
            key={`${id}.panelFeedId`}
            editDotRight='-110px'
            items={panels}
            edited={circuitFieldIsEdited(id, 'panelFeedId')}
            selectedItem={makeListSelectorItem(
              panelFeedId,
              panels.find((item) => item.id === panelFeedId)?.name,
            )}
            notSetItemText='--'
            notSetLabelText='--'
            notSetItemValue=''
            updateValue={getUpdateValueHandlers().circuits[id].panelFeedId}
            disabled={meteredCircuits.includes(id)}
            menuPortalTarget
          />
        )
      },
      ...columnWidths.buildingSystemId,
    },
    {
      accessor: 'description',
      Header: 'Circuit Description',
      Cell: ({ original: { id }, value }: FTCell) => (
        <Input
          alwaysDraw
          disabled={
            meteredCircuits.includes(id) || gangedCircuitChildren.has(id)
          }
          editDotLeft='166px'
          type='text'
          hideEditDotOnHover={false}
          key={`${id}.description`}
          name={`circuitsById.${id}.description`}
          edited={circuitFieldIsEdited(id, 'description')}
          value={value}
          placeholder='--'
          onChange={getUpdateValueHandlers().circuits[id].description}
        />
      ),
      ...columnWidths.description,
    },
    {
      accessor: 'buildingSystemId',
      Header: 'Building System',
      Cell: ({ original: { id }, value }: FTCell) => (
        <BuildingSystemListSelector
          items={buildingSystems}
          unsettable
          edited={circuitFieldIsEdited(id, 'buildingSystemId')}
          editDotRight='-110px'
          notSetItemText='--'
          notSetLabelText='--'
          notSetItemValue=''
          selectedItem={makeListSelectorItem(buildingSystemsById[value]?.name)}
          updateValue={getUpdateValueHandlers().circuits[id].buildingSystemId}
          tabIndex={0}
          hideEditDotOnHover={false}
          disabled={
            meteredCircuits.includes(id) || gangedCircuitChildren.has(id)
          }
          menuPortalTarget
        />
      ),
      ...columnWidths.buildingSystemId,
    },
    {
      accessor: 'equipmentName',
      Header: 'Equipment Name',
      Cell: ({ original: { id }, value }: FTCell) => (
        <Input
          alwaysDraw
          disabled={
            meteredCircuits.includes(id) || gangedCircuitChildren.has(id)
          }
          editDotLeft='166px'
          hideEditDotOnHover={false}
          key={`${id}.equipmentName`}
          name={`circuitsById.${id}.equipmentName`}
          edited={circuitFieldIsEdited(id, 'equipmentName')}
          value={value}
          placeholder='--'
          onChange={getUpdateValueHandlers().circuits[id].equipmentName}
        />
      ),
      ...columnWidths.equipmentName,
    },
    {
      accessor: 'buildingArea',
      Header: 'Building Area',
      Cell: ({ original: { id }, value }: FTCell) => (
        <Input
          alwaysDraw
          disabled={
            meteredCircuits.includes(id) || gangedCircuitChildren.has(id)
          }
          editDotLeft='166px'
          hideEditDotOnHover={false}
          key={`${id}.buildingArea`}
          name={`circuitsById.${id}.buildingArea`}
          edited={circuitFieldIsEdited(id, 'buildingArea')}
          value={value}
          placeholder='--'
          onChange={getUpdateValueHandlers().circuits[id].buildingArea}
        />
      ),
      ...columnWidths.buildingArea,
    },
    {
      accessor: 'amperage',
      Header: 'Amperage',
      Cell: ({ original: { id }, value }: FTCell) => (
        <AmperageListSelector
          items={validAmperageValues}
          edited={circuitFieldIsEdited(id, 'amperage')}
          editDotRight='-20px'
          unsettable
          notSetItemText='--'
          notSetLabelText='--'
          notSetItemValue=''
          selectedItem={makeListSelectorItem(value)}
          updateValue={getUpdateValueHandlers().circuits[id].amperage}
          tabIndex={0}
          disabled={
            meteredCircuits.includes(id) || gangedCircuitChildren.has(id)
          }
          menuPortalTarget
        />
      ),
      ...columnWidths.amperage,
    },
    {
      accessor: 'CTSizeExpected',
      Header: 'CT Size Expected',
      Cell: ({ original: { id }, value }: FTCell) => (
        <StyledListSelector
          items={validCtTypes}
          edited={circuitFieldIsEdited(id, 'CTSizeExpected')}
          editDotRight='0px'
          selectedItem={makeListSelectorItem(value)}
          notSetItemText='--'
          notSetLabelText='--'
          notSetItemValue=''
          updateValue={getUpdateValueHandlers().circuits[id].CTSize}
          disabled={
            meteredCircuits.includes(id) || gangedCircuitChildren.has(id)
          }
          menuPortalTarget
        />
      ),
      ...columnWidths.CTSizeExpected,
    },
    {
      id: 'actions',
      Cell: getActionsCell,
      minWidth: 10,
      maxWidth: 10,
      zIndex: 1,
    },
  ]

  if (!isNumbered) {
    editableColumns = editableColumns.filter(
      (column) => column.accessor !== 'breakerType',
    )
  }

  return (
    <>
      <UnsavedChanges
        when={dirty && !isUpdateSuccess}
        message={
          !inCreateMode ? UNSAVED_CHANGES_MESSAGE : UNSAVED_PANEL_MESSAGE
        }
      />
      <FormContainterStyled>
        {dirty && (
          <ResetActionStyled
            onClick={() => {
              showConfirmModal({
                modalWidth: '430px',
                onPrimaryAction: undefined,
                onSecondaryAction: resetForm,
                primaryActionText: 'No',
                renderBody:
                  isNumbered ?
                    renderResetCircuitBreakersConfirm
                  : renderResetSwitchesConfirm,
                secondaryActionText: 'Yes',
              })
            }}
          >
            <HistoryIconStyed />
            Reset
          </ResetActionStyled>
        )}
        {!inCreateMode && !leftCircuits.length && !rightCircuits.length && (
          <AddPanelActionButtonStyled onClick={handleCreateMockCircuits}>
            <AddCircleOutlineIconStyled />
            {isNumbered ? 'Add Circuits' : 'Add Switches'}
          </AddPanelActionButtonStyled>
        )}
        <PanelCircuitsTable
          leftCircuits={leftCircuits}
          rightCircuits={rightCircuits}
          isNumbered={isNumbered}
          circuitsLoading={circuitsLoading}
          columns={editableColumns}
        />
        <FormButtons
          onSubmit={handleSubmit}
          submitText={inCreateMode ? 'Create Panel' : 'Save'}
          onCancel={goBack}
          cancelText='Cancel'
        />
      </FormContainterStyled>
    </>
  )
}

export default Form
