import { saveAs } from 'file-saver'
import html2pdf from 'html2pdf.js'
import JSZip from 'jszip'
import moment from 'moment'
import React, { ComponentType, useMemo, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'

import { defaultSwitchColumns } from './constants'
import Accordion, {
  MainContainerStyled as AccordionContainer,
} from '../../components/Accordion'
import Breadcrumbs, { BreadcrumbsStyles } from '../../components/Breadcrumbs'
import RedaptiveReactTable from '../../components/RedaptiveReactTable'
import Spinner from '../../components/Spinner'
import TabPane from '../../components/Tabs/TabPane'
import type {
  FTFetchMeterInstallSubmissionDetailsAction,
  FTFetchMeterInstallSubmissionPhotosAction,
  FTMeterInstallSubmissionDetails,
  FTMeterInstallSubmissionPhotos,
} from '../../ducks/meterInstallSubmissions/meterInstallSubmissionDetails'
import {
  actions as meterInstallSubmissionDetailsActions,
  selectMeterInstallSubmissionDetailsEntity,
  utils,
} from '../../ducks/meterInstallSubmissions/meterInstallSubmissionDetails'
import { fieldNameMap } from '../../ducks/meters/generation'
import type { FTWithRouter } from '../../types'
import type { FTMeterInstallSubmissionPhoto } from '../../types/meterInstallSubmissions/MeterInstallSubmissionDetailsTypes'
import { FTPhotoDataObject } from '../../types/meterInstallSubmissions/MeterInstallSubmissionDetailsTypes.d'
import { truncateText } from '../../utils'
import {
  ctSizeMapping,
  submissionPhotoObjectTypeMapping,
} from '../../utils/MeterInstallSubmissionMappings'

type FTProps = {
  actions: {
    fetchMeterInstallSubmissionDetails: (
      params: FTFetchMeterInstallSubmissionDetailsAction,
    ) => {}
    fetchMeterInstallSubmissionPhotos: (
      params: FTFetchMeterInstallSubmissionPhotosAction,
    ) => {}
    resetMeterInstallSubmissionState: () => {}
  }
  details: FTMeterInstallSubmissionDetails
  photos: FTMeterInstallSubmissionPhotos
  detailsLoading: boolean
  photosLoading: boolean
} & FTWithRouter

const EntryStyled = styled.div`
  display: flex;
  flex-direction: row;
  width: 650px;
  margin-bottom: 16px;
`
const EntryKeyStyled: ComponentType<{
  indent?: boolean
}> = styled.div`
  font-weight: 600;
  color: #6c6d6e;

  width: ${({ indent }) => (indent ? '230px' : '250px')};
`
const EntryValueStyled = styled.div`
  width: 375px;
  overflow-wrap: break-word;
  color: ${({ danger }) => (danger ? '#C70D08' : '#4a4a4a')};
`

const BreakerKey: ComponentType<{
  indent?: boolean
}> = styled.div`
  color: #6c6d6e;
  width: ${({ indent }) => (indent ? '230px' : '250px')};
`

const BreakerValue = styled.div`
  width: 375px;
  overflow-wrap: break-word;
  color: #4a4a4a;
`

const InfoContainer: ComponentType<{
  indent?: boolean
}> = styled.div`
  padding-left: ${({ indent }) => (indent ? '20px' : '')};
`
const InstallPhotosContainerStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
`
const CellRouterPhotosContainerStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${EntryValueStyled} {
    width: 800px;
  }
`
const InstallPhotosSectionStyled = styled.div`
  margin-right: 66px;
  margin-bottom: 58px;
`
const InstallPhotosSectionTitleStyled = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 16px;
`
const InstallPhotosSectionBodyStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  img {
    border-radius: 4px;
    width: 165px;
    height: 165px;
    padding-right: 24px;
  }
`
const EmptyPhotoContainerStyles = styled.div`
  margin-left: 600px;
  margin-top: 60px;
  font-style: italic;
`
const BreadcrumbsStyled = styled.div`
  margin-bottom: 20px;
  ${BreadcrumbsStyles} {
    a {
      color: #337ab7;
    }
  }
`
const PanelFeedTitleStyled = styled.div`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 23px;
`
const CircuitPanelsContainerStyled = styled.div`
  margin-top: 27px;
  display: flex;
  flex-direction: row;
  width: 1300px;
  justify-content: space-between;
`
const CircuitTitleStyled = styled.div`
  color: #4a4a4a;
  font-weight: 600;
  padding-left: 10px;
`
const CircuitStyled = styled.div``
const PanelInfoContainerStyled = styled.div`
  display: flex;
  justify-content: space-between;
  width: 1300px;
`
const PhotosContainerStyled = styled.div`
  margin-top: 32px;
  width: 100%;
`
const ExportButtonStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #485da0;
  ${(props) => (props.loading ? 'filter: saturate(30%) opacity(80%);' : '')};
  color: #fff;
  border-radius: 4px;
  border: 0;
  cursor: pointer;
  font-family: 'Public Sans', sans-serif;
  font-size: 12px;
  font-weight: 600;
  height: 36px;
  min-width: 64px;
  padding: 0 16px;
  &:nth-child(2) {
    margin-left: 10px;
  }
  span {
    margin-left: 5px;
    margin-top: -2px;
    &.disabled {
      color: #ccc;
    }
  }
`
const ErrorStyled = styled.div`
  color: #ff0000;
  font-weight: 400;
  margin-bottom: 10px;
  margin-top: 10px;
`
const ContainerStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 33px;
  }
`
const ButtonGroupStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
`
const TitleStyled = styled.div``
const MainContainer = styled.div`
  font-family: 'Avenir next';
  font-size: 14px;
  line-height: 20px;
  padding-bottom: 50px;
  .pb {
    display: none;
    page-break-after: always;
  }
  ${AccordionContainer} {
    padding: 0px;
    margin-bottom: 32px;
  }
  &.hidden {
    display: none;
  }
  &.pdfview {
    display: block !important;
    width: 724px; //width for A4
    padding: 0 20px;
    .pb {
      display: block;
    }
    ${TitleStyled} {
      font-family: 'Avenir Next';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
    }
    ${PanelInfoContainerStyled} {
      display: flex;
      flex-wrap: wrap;
      width: auto;
      ${EntryStyled} {
        width: 100%;
      }
    }
    ${PanelFeedTitleStyled} {
      margin-top: 20px;
    }
    ${CircuitPanelsContainerStyled} {
      width: auto;
      display: block;
      ${CircuitStyled} {
        margin-bottom: 20px;
      }
    }
    ${CircuitStyled} {
      width: 100%;
    }
    ${InstallPhotosSectionBodyStyled}, ${InstallPhotosContainerStyled} {
      img {
        width: 90%;
        height: auto;
        padding-right: 0;
        margin-bottom: 10px;
        &.landscape {
          width: 90%;
          height: auto;
        }
        &.portrait {
          width: auto;
          height: calc(1024px * 0.8);
        }
      }
    }

    .cell-router-photo {
      display: block !important;
    }
    .img-pdf {
      margin-bottom: 50px;
    }
  }
`

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`

const ListItem = styled.li`
  span {
    position: relative;
    left: -5px;
  }
`

const MaxWidth = styled.span`
  max-width: 100px;
  overflow-wrap: break-word;
`

const MeterInstallSubmissionDetails = (props: FTProps) => {
  const {
    actions,
    match: {
      params: { meterSubmissionId },
    },
    details,
    detailsLoading,
    photos,
    photosLoading,
  } = props
  const {
    customerName,
    siteAddress,
    installerDetails,
    submissionTime,
    opportunityId,
    meterInstallationDetails,
    postConfigTestResults,
  } = details

  React.useEffect(
    () => () => {
      actions.resetMeterInstallSubmissionState()
    },
    [],
  )

  const { powerSource } = meterInstallationDetails || {}
  const [isPdfView, setIsPdfView] = useState(false)
  const [zipError, setZipError] = useState('')

  const exportContentRef = useRef(null)

  const [mappedPhotos, setMappedPhotos] = useState<any>([])

  const [mappedPhotoLoaded, setMappedPhotoLoaded] = useState(false)

  React.useEffect(() => {
    if (photos && photos.length > 0) {
      setMappedPhotos(
        photos.map((img) => ({
          ...img,
          dataURI: '',
          isDataURILoaded: false,
        })),
      )
      setMappedPhotoLoaded(true)
    }
  }, [photos])

  React.useEffect(() => {
    actions.fetchMeterInstallSubmissionDetails({
      meterSubmissionId,
    })
  }, [])

  React.useEffect(() => {
    if (
      meterInstallationDetails &&
      Object.keys(meterInstallationDetails).length
    ) {
      const { jobCode, macAddress } = meterInstallationDetails
      actions.fetchMeterInstallSubmissionPhotos({
        macAddress,
        jobCode,
        userId:
          installerDetails?.installerId ||
          '021a5b03-0a2b-4c9b-b28e-cd713e3a2e3c',
      })
    }
  }, [meterInstallationDetails])

  React.useEffect(() => {
    if (mappedPhotos && mappedPhotos.length) {
      mappedPhotos.forEach((img, index) => {
        fetch(img.preSignedUrl)
          .then((response) => response.blob())
          .then((blob) => {
            const reader = new FileReader()
            reader.readAsDataURL(blob)
            reader.onloadend = () => {
              const newPhotos = [...mappedPhotos]
              newPhotos[index].dataURI = reader.result
              newPhotos[index].isDataURILoaded = true
              const imageElem = new Image()
              // Set the src of the image to the data URI
              imageElem.src = reader.result as string
              // Add an onload handler to get the width and height after the image loads
              imageElem.onload = function () {
                newPhotos[index].width = this.width
                newPhotos[index].height = this.height
                // Update the state after the width and height are set
                setMappedPhotos(newPhotos)
              }
            }
          })
          .catch((error) => {
            const newPhotos = [...mappedPhotos]
            newPhotos[index].statusOfDataURI = 'error'
            setMappedPhotos(newPhotos)
            if (error) {
              setZipError('Error with PDF export functionality.')
            }
          })
      })
    }
  }, [mappedPhotoLoaded])

  const isAllImageDataURILoaded = () => {
    if (mappedPhotos.length === 0) {
      return true
    }
    return (
      mappedPhotos.length > 0 &&
      mappedPhotos.every((img) => img.isDataURILoaded)
    )
  }
  const breadcrumbs = React.useMemo(
    () => [
      { href: '/reports', text: 'Tools & Reports' },
      {
        href: '/reports/meter-install-submissions',
        text: 'Meter Install Submissions',
      },
      {
        href: `/reports/meter-install-submissions/${meterSubmissionId}`,
        text:
          submissionTime ?
            `${moment(submissionTime).format('MM/DD/YYYY, hh:mm:ss A')} UTC`
          : '...',
      },
    ],
    [submissionTime],
  )

  const photoIdsByObjectId = useMemo(() => {
    const tempObject = {}

    if (mappedPhotos && !!mappedPhotos.length) {
      mappedPhotos.forEach((photo) => {
        if (tempObject[photo.objectId]) {
          tempObject[photo.objectId].push(photo.id)
        } else {
          tempObject[photo.objectId] = [photo.id]
        }
      })
    }
    return tempObject
  }, [mappedPhotos])

  const photosById: { string: FTMeterInstallSubmissionPhoto } =
    mappedPhotos && mappedPhotos.length ?
      mappedPhotos.reduce((prev, curr) => ({ ...prev, [curr.id]: curr }), {})
    : {}

  const renderPhotosForView = (
    data: Array<FTMeterInstallSubmissionPhoto>,
    sectionTitle,
  ) => {
    if (!data?.length) return null
    return (
      <>
        {data.map((item) => (
          <a href={item.preSignedUrl} target='_blank' rel='noreferrer'>
            <img
              key={item.id}
              src={item.preSignedThumbnailUrl}
              alt={`${sectionTitle} Thumbnail`}
              loading='lazy'
            />
          </a>
        ))}
      </>
    )
  }

  const renderPhotosForPdf = (
    data: Array<FTMeterInstallSubmissionPhoto>,
    sectionTitle,
  ) => {
    if (!data?.length) return null
    return (
      <>
        {data.map((item, index) => (
          <>
            {index > 0 && <div className='pb' />}
            <a href={item.preSignedUrl} target='_blank' rel='noreferrer'>
              <img
                key={item.id}
                src={item.dataURI}
                alt={`${sectionTitle} Full Size`}
                className={
                  item.width > item.height ?
                    'img-pdf landscape'
                  : 'img-pdf portrait'
                }
              />
            </a>
          </>
        ))}
      </>
    )
  }

  // Can be used to render photos in the sections
  const renderPhotosInSection = React.useCallback(
    (objectType: string) => {
      const routerPhotos = mappedPhotos.filter(
        (photo) => photo.objectType === objectType,
      )
      if (!routerPhotos || !routerPhotos.length)
        return <div>-- No photos available --</div>
      return (
        <InstallPhotosContainerStyled>
          {isPdfView ?
            renderPhotosForPdf(routerPhotos, objectType)
          : renderPhotosForView(routerPhotos, objectType)}
        </InstallPhotosContainerStyled>
      )
    },
    [mappedPhotos, isPdfView],
  )

  const renderFinishedInstallPhotos = React.useCallback(
    () => (
      <Accordion
        title='Finished Install Photos'
        Content={() => renderPhotosInSection('CT')}
        isExpandedInitially={isPdfView}
      />
    ),
    [renderPhotosInSection, isPdfView],
  )

  const renderPanelSchedulePhotos = React.useCallback(
    () => (
      <Accordion
        title='Panel Schedule Photos'
        Content={() => renderPhotosInSection('WRAP_UP')}
        isExpandedInitially={isPdfView}
      />
    ),
    [renderPhotosInSection, isPdfView],
  )

  const renderLockoutTagoutPhotos = React.useCallback(
    () => (
      <Accordion
        title='Lockout Tagout Evidence'
        Content={() => renderPhotosInSection('SAFETY_WARNING')}
        isExpandedInitially={isPdfView}
      />
    ),
    [renderPhotosInSection, isPdfView],
  )

  const photoDataByObjectId: { string: FTPhotoDataObject } =
    Object.keys(photoIdsByObjectId).length &&
    Object.entries(photoIdsByObjectId).reduce((prev, curr) => {
      const dataObject = {
        PANEL: [],
        CT: [],
        ROUTER: [],
        SAFETY_WARNING: [],
        WRAP_UP: [],
        SWITCH: [],
        PPE_EVIDENCE: [],
        LEFT_OVER_EQUIPMENT: [],
      }
      curr[1].forEach((id) => {
        const type = photosById[id]?.objectType
        if (type) {
          dataObject[type].push(photosById[id])
        }
      })
      return { ...prev, [curr[0]]: dataObject }
    }, {})

  const panelsById = React.useMemo(
    () =>
      meterInstallationDetails &&
      !!Object.keys(meterInstallationDetails).length &&
      meterInstallationDetails.panels.reduce(
        (prev, curr) => ({ ...prev, [curr.id]: { ...curr } }),
        {},
      ),
    [meterInstallationDetails],
  )

  const ctSizesById = React.useMemo(
    () =>
      meterInstallationDetails &&
      !!Object.keys(meterInstallationDetails).length &&
      meterInstallationDetails.currentTransformer.ctSizes.reduce(
        (prev, curr) => ({ ...prev, [curr.id]: { ...curr } }),
        {},
      ),
    [meterInstallationDetails],
  )

  const ctChannelsByPanelId = React.useMemo(() => {
    if (
      !meterInstallationDetails ||
      !Object.keys(meterInstallationDetails).length
    ) {
      return null
    }
    const validChannels =
      meterInstallationDetails.currentTransformer.ctChannels.filter(
        (channel) => channel.panelId,
      )
    const tempObject = {}

    validChannels.forEach((channel) => {
      if (tempObject[channel.panelId]) {
        tempObject[channel.panelId].push(channel)
      } else {
        tempObject[channel.panelId] = [channel]
      }
    })

    return tempObject
  }, [meterInstallationDetails])

  const switches = React.useMemo(
    () =>
      meterInstallationDetails &&
      meterInstallationDetails?.panels
        .filter((panel) => panel.panelType === 'SWITCH_BOARD')
        .reduce((prev, curr) => [...prev, ...(curr.switches || [])], []),
    [meterInstallationDetails],
  )

  const switchesById = React.useMemo(
    () =>
      switches?.reduce(
        (prev, curr) => ({ ...prev, [curr.id]: { ...curr } }),
        {},
      ),
    [switches],
  )

  const switchBreakersMapping = React.useMemo(
    () =>
      (
        meterInstallationDetails &&
        !!Object.keys(meterInstallationDetails).length
      ) ?
        meterInstallationDetails.panels
          .filter((panel) => panel.panelType === 'SWITCH_BOARD')
          .reduce((prevPanel, currPanel) => {
            const { ctChannels } = meterInstallationDetails.currentTransformer
            const validChannels = ctChannels.filter(
              (item) => item.panelId === currPanel.id,
            )
            const channelsGroupedBySwitchId = validChannels
              .filter((channel) => channel.switchId)
              .reduce(
                (prevChannels, currChannel) => ({
                  ...prevChannels,
                  [currChannel.switchId]:
                    prevChannels[currChannel.switchId] ?
                      [...prevChannels[currChannel.switchId], currChannel]
                    : [currChannel],
                }),
                {},
              )

            const switchIdVisited = Object.keys(
              channelsGroupedBySwitchId,
            ).reduce((p, c) => ({ ...p, [c]: false }), {})
            let count = 1
            const breakerData = validChannels.reduce((prev, curr) => {
              const tenants = meterInstallationDetails?.tenants || []
              const tenantId = switchesById[curr.switchId]?.tenantId
              if (!switchIdVisited[curr.switchId] && curr.switchId) {
                const phaseGroup = new Array(
                  channelsGroupedBySwitchId[curr.switchId].length,
                )
                  .fill(`0${count}`.slice(-2))
                  .join('-')
                const enhancedChannels = channelsGroupedBySwitchId[
                  curr.switchId
                ].map((channel) => ({
                  ...ctSizesById[channel.ctSizeId],
                  ...channel,
                  breakerType:
                    switchesById[curr.switchId]?.switchName || 'Switchboard',
                  tenant: tenants.find((t) => t.id === tenantId)?.name || '',
                  tenantSpace: tenantId ? 'Yes' : 'No',
                  breakerNumber: count,
                  phaseGroup,
                }))
                count += 1
                switchIdVisited[curr.switchId] = true
                return [...prev, ...enhancedChannels]
              }
              return prev
            }, [])

            return { ...prevPanel, [currPanel.id]: [...breakerData] }
          }, {})
      : [],
    [meterInstallationDetails, ctSizesById, switchesById],
  )

  const panelBreakersMapping = React.useMemo(
    () =>
      (
        meterInstallationDetails &&
        !!Object.keys(meterInstallationDetails).length
      ) ?
        meterInstallationDetails.panels
          .filter((panel) => panel.panelType === 'PANEL_BOARD')
          .reduce((prev, curr) => {
            const { ctChannels } = meterInstallationDetails.currentTransformer

            const validChannels = ctChannels.filter(
              (item) => item.panelId === curr.id && !item.switchId,
            )

            let i = 0
            const breakerNumberMapping = validChannels.reduce(
              (prevCh, currCh) => ({
                ...prevCh,
                [++i]: { ...currCh, ...ctSizesById[currCh.ctSizeId] },
              }),
              {},
            )

            return {
              ...prev,
              [curr.id]: {
                ...breakerNumberMapping,
              },
            }
          }, {})
      : {},
    [meterInstallationDetails, ctSizesById],
  )

  // Helper function to render string sections
  const renderInfoSection = React.useCallback((data, indent = false) => {
    if (!Object.keys(data).length) return null
    return (
      <InfoContainer indent={indent}>
        {Object.entries(data).map((obj) => (
          <EntryStyled key={obj[0]}>
            <EntryKeyStyled indent={indent}>{obj[0]}</EntryKeyStyled>
            <EntryValueStyled>{obj[1] || '-'}</EntryValueStyled>
          </EntryStyled>
        ))}
      </InfoContainer>
    )
  }, [])

  const renderBreakerValueSection = React.useCallback(
    (data, indent = false) => {
      const keys = Object.keys(data)
      if (!keys.length) return null
      if (keys.length >= 10 && !isPdfView) {
        const half = Math.ceil(keys.length / 2)
        const firstHalf = keys.slice(0, half)
        const secondHalf = keys.slice(half)
        const firstObj: Record<string, string> = {}
        const secondObj: Record<string, string> = {}
        firstHalf.forEach((key) => {
          firstObj[key] = data[key]
        })
        secondHalf.forEach((key) => {
          secondObj[key] = data[key]
        })
        return (
          <StyledGrid>
            <InfoContainer indent={indent}>
              {Object.entries(firstObj).map((obj) => (
                <EntryStyled key={obj[0]}>
                  <BreakerKey indent={indent}>{obj[0]}</BreakerKey>
                  <BreakerValue>{obj[1] || '-'}</BreakerValue>
                </EntryStyled>
              ))}
            </InfoContainer>
            <InfoContainer indent={indent}>
              {Object.entries(secondObj).map((obj) => (
                <EntryStyled key={obj[0]}>
                  <BreakerKey indent={indent}>{obj[0]}</BreakerKey>
                  <BreakerValue>{obj[1] || '-'}</BreakerValue>
                </EntryStyled>
              ))}
            </InfoContainer>
          </StyledGrid>
        )
      }
      return (
        <InfoContainer indent={indent}>
          {Object.entries(data).map((obj) => (
            <EntryStyled className='row-break' key={obj[0]}>
              <BreakerKey indent={indent}>{obj[0]}</BreakerKey>
              <BreakerValue>{obj[1] || '-'}</BreakerValue>
            </EntryStyled>
          ))}
        </InfoContainer>
      )
    },
    [isPdfView],
  )

  const appendColonToKey = (obj) => {
    const newObj = {}
    // eslint-disable-next-line no-restricted-syntax
    for (const key in obj) {
      newObj[`${key} :`] = obj[key]
    }
    return newObj
  }

  // Helper function to render string sections and photos
  const renderInfoSectionAndPhotos = React.useCallback(
    (data, objectName, indent = false) => {
      if (!Object.keys(data).length) return null
      return (
        <InfoContainer indent={indent}>
          {Object.entries(data).map((obj) => (
            <EntryStyled key={obj[0]}>
              <EntryKeyStyled indent={indent}>{obj[0]}</EntryKeyStyled>
              <EntryValueStyled>{obj[1] || '-'}</EntryValueStyled>
            </EntryStyled>
          ))}
          <EntryStyled key={submissionPhotoObjectTypeMapping[objectName]}>
            <EntryKeyStyled>
              {submissionPhotoObjectTypeMapping[objectName]}
            </EntryKeyStyled>
          </EntryStyled>
          <PhotosContainerStyled>
            <div className='pb' />
            {renderPhotosInSection(objectName)}
          </PhotosContainerStyled>
        </InfoContainer>
      )
    },
    [renderPhotosInSection],
  )

  const renderPanelFeedSection = React.useCallback(
    (channels) => {
      const mainFeeds = channels.filter((channel) => channel.mainFeed)
      const sizes = Array.from(
        new Set(
          mainFeeds.map((channel) => {
            const ctSize = ctSizesById[channel.ctSizeId]?.ctType
            return ctSize && ctSizeMapping[ctSize] ?
                `${ctSizeMapping[ctSize].text} (${ctSizeMapping[ctSize].subText})`
              : null
          }),
        ),
      )

      const mainFeedsPhaseA = mainFeeds
        .filter((feed) => feed.mainFeed === 'A')
        .map((ch) => `${ch.meterChannel[0]}-${ch.meterChannel[1]}`)
      const mainFeedsPhaseB = mainFeeds
        .filter((feed) => feed.mainFeed === 'B')
        .map((ch) => `${ch.meterChannel[0]}-${ch.meterChannel[1]}`)
      const mainFeedsPhaseC = mainFeeds
        .filter((feed) => feed.mainFeed === 'C')
        .map((ch) => `${ch.meterChannel[0]}-${ch.meterChannel[1]}`)

      const panelFeedInfo = {
        'CT Size (mm) for 1st switch': sizes.join(', '),
        'Phase A - CT Number(s) used': mainFeedsPhaseA.join(', '),
        'Phase B - CT Number(s) used': mainFeedsPhaseB.join(', '),
        'Phase C - CT Number(s) used': mainFeedsPhaseC.join(', '),
      }

      return renderInfoSection(panelFeedInfo)
    },
    [ctSizesById],
  )

  // Helper function to render photo sections
  const renderPhotoSection = (
    data: Array<FTMeterInstallSubmissionPhoto>,
    sectionTitle,
  ) => {
    if (!data?.length) return null
    return (
      <InstallPhotosSectionStyled>
        <div className='pb' />
        <InstallPhotosSectionTitleStyled>
          {sectionTitle}
        </InstallPhotosSectionTitleStyled>
        <InstallPhotosSectionBodyStyled>
          {isPdfView ?
            renderPhotosForPdf(data, sectionTitle)
          : renderPhotosForView(data, sectionTitle)}
        </InstallPhotosSectionBodyStyled>
      </InstallPhotosSectionStyled>
    )
  }

  // Helper function to render panels photos by Id
  const renderPanelPhotosById = (id) => {
    if (!photoDataByObjectId[id]) {
      return (
        <EmptyPhotoContainerStyles>
          No photos available
        </EmptyPhotoContainerStyles>
      )
    }
    const { PANEL, SWITCH, PPE_EVIDENCE } = photoDataByObjectId[id]
    return (
      <InstallPhotosContainerStyled>
        {renderPhotoSection(PANEL, submissionPhotoObjectTypeMapping.PANEL)}
        {renderPhotoSection(
          PPE_EVIDENCE,
          submissionPhotoObjectTypeMapping.PPE_EVIDENCE,
        )}
        {renderPhotoSection(SWITCH, submissionPhotoObjectTypeMapping.SWITCH)}
      </InstallPhotosContainerStyled>
    )
  }

  const renderMeterInfo = React.useCallback(() => {
    const meterInfo = {
      'Meter MAC': meterInstallationDetails?.macAddress || '',
      'Submission Time':
        submissionTime ?
          `${moment(submissionTime).format('MM/DD/YYYY, hh:mm:ss A')} UTC`
        : '-',
      'Opportunity ID': opportunityId,
      'Workmarket Assignment #':
        meterInstallationDetails?.jobCode === opportunityId ?
          '-'
        : meterInstallationDetails?.jobCode,
    }

    return (
      <Accordion
        title='Meter Info'
        Content={() => renderInfoSection(meterInfo)}
        isExpandedInitially
      />
    )
  }, [meterInstallationDetails, submissionTime, details])

  const renderInstallLocationInfo = React.useCallback(() => {
    const locationInfo = {
      'Customer Name': customerName,
      'Site Address': siteAddress,
    }
    return (
      <Accordion
        title='Install Location'
        Content={() => renderInfoSection(locationInfo)}
        isExpandedInitially
      />
    )
  }, [customerName, siteAddress])

  const circuitColumns = React.useMemo(() => {
    const cols = [
      {
        accessor: 'breakerNumber',
        Cell: ({ value }) => value || '-',
        Header: 'Breaker',
        width: 60,
      },
      {
        accessor: 'breakerType',
        Cell: ({ value }) => utils.breakerTypes[value] || value || 'Invalid',
        Header: 'Type/Name',
      },
      {
        accessor: 'phase',
        Cell: ({ value }) => value || '-',
        Header: 'Phase',
        width: 60,
      },
      {
        accessor: 'ctSize',
        Cell: ({ value }) => (value ? `${value}A` : '-'),
        Header: 'CT Size',
        width: 70,
      },
      {
        accessor: 'meterChannel',
        Cell: ({ value }) => value || '-',
        Header: 'Meter Channel',
        width: 100,
      },
      {
        accessor: 'phaseGroup',
        Cell: ({ value }) => value || '-',
        Header: 'Phase Group',
      },
    ]
    if (isPdfView) {
      cols[cols.length - 1].width = 250
    }
    return cols
  }, [isPdfView])

  const switchCircuitColumns = React.useMemo(() => {
    const cols = [
      ...defaultSwitchColumns,
      {
        accessor: 'tenantSpace',
        Cell: ({ value }: { value: string }) => value || 'No',
        Header: 'Tenant Space',
      },
      {
        accessor: 'tenant',
        Cell: ({ value }: { value: string }) =>
          isPdfView ? truncateText(value, 15) : value || '',
        Header: 'Tenant Name',
      },
    ]
    return cols
  }, [isPdfView])

  const renderPanelsCircuitBreakers = React.useCallback(
    (panelId) => {
      const getBreakerTableData = (breakerAndPhaseMapping) =>
        breakerAndPhaseMapping.map((breaker) => {
          const currentPanelBreaker = Object.values(
            panelBreakersMapping[panelId],
          ).find(
            (item) =>
              item.breakerNumber === breaker.breakerNumber &&
              item.phase === breaker.phase,
          )
          return {
            ...breaker,
            ctSize: currentPanelBreaker?.ctType,
            meterChannel: currentPanelBreaker?.meterChannel,
            phaseGroup: currentPanelBreaker?.phaseGroup,
          }
        })

      const leftCircuitData = getBreakerTableData(
        panelsById[panelId]?.leftSideBreaker.breakerAndPhaseMapping,
      )
      const rightCircuitData = getBreakerTableData(
        panelsById[panelId]?.rightSideBreaker.breakerAndPhaseMapping,
      )

      return (
        <CircuitPanelsContainerStyled>
          <div className='pb' />
          <CircuitStyled>
            <CircuitTitleStyled>Left Side</CircuitTitleStyled>
            <RedaptiveReactTable
              data={leftCircuitData}
              columns={circuitColumns}
              filterable={false}
              sortable={false}
            />
          </CircuitStyled>
          <div className='pb' />
          <CircuitStyled>
            <CircuitTitleStyled>Right Side</CircuitTitleStyled>
            <RedaptiveReactTable
              data={rightCircuitData}
              columns={circuitColumns}
              filterable={false}
              sortable={false}
            />
          </CircuitStyled>
        </CircuitPanelsContainerStyled>
      )
    },
    [panelsById, circuitColumns],
  )

  const renderSwitchBoardCircuitBreakers = React.useCallback(
    (switchId) => {
      const breakerTableData = switchBreakersMapping[switchId].map(
        (breaker) => ({
          ...breaker,
          ctSize: breaker?.ctType,
        }),
      )
      return (
        <CircuitPanelsContainerStyled>
          <div className='pb' />
          <CircuitStyled>
            <CircuitTitleStyled>
              {panelsById[switchId]?.name}
            </CircuitTitleStyled>
            <RedaptiveReactTable
              data={breakerTableData}
              columns={switchCircuitColumns}
              filterable={false}
              sortable={false}
            />
          </CircuitStyled>
        </CircuitPanelsContainerStyled>
      )
    },
    [switchBreakersMapping, circuitColumns, panelsById],
  )

  const renderPdfPanelsInfo = (panelTabs) => {
    const panelsInfo = panelTabs.map((panel) => {
      const { text, render } = panel
      return (
        <div key={text}>
          <TitleStyled>{text}</TitleStyled>
          {render()}
        </div>
      )
    })
    return panelsInfo
  }

  const renderPanelsInfo = () => {
    const panelTabs =
      !!meterInstallationDetails &&
      !!Object.keys(meterInstallationDetails).length &&
      meterInstallationDetails.panels.map((panel) => {
        const {
          name,
          location,
          isBreakersNumbered,
          type,
          voltage,
          isEnergizedPanel,
          isAuthorizedForEnergizedPanel,
        } = panel || {}
        const {
          isMeterPanelClosedTimeInMS,
          isMeterPanelPowerRestoredTimeInMS,
        } = meterInstallationDetails
        const panelInfo = {
          Name: name || 'Invalid',
          'Panel location': location || '',
          Numbered: isBreakersNumbered ? 'YES' : 'NO',
          Configuration: utils.panelConfigurations[type] || 'Invalid',
          Voltage: fieldNameMap.get(voltage),
          'Live Panel Work (Yes/No)': isEnergizedPanel ? 'YES' : 'NO',
          'Authorized to Work on Live Panel':
            isAuthorizedForEnergizedPanel ? 'YES' : 'NO',
          'Panel closed affirmation': isMeterPanelClosedTimeInMS ? 'YES' : 'NO',
          'Panel powered affirmation':
            isMeterPanelPowerRestoredTimeInMS ? 'YES' : 'NO',
        }

        const TabContent = () => (
          <div>
            <PanelInfoContainerStyled>
              <div>{renderInfoSection(panelInfo)}</div>
              <div>
                <PanelFeedTitleStyled>Panel Feed</PanelFeedTitleStyled>
                {renderPanelFeedSection(ctChannelsByPanelId[panel.id] || [])}
              </div>
            </PanelInfoContainerStyled>

            {panel.panelType === 'PANEL_BOARD' ?
              renderPanelsCircuitBreakers(panel.id)
            : null}
            {panel.panelType === 'SWITCH_BOARD' ?
              renderSwitchBoardCircuitBreakers(panel.id)
            : null}
            <PhotosContainerStyled>
              {photosLoading ?
                <Spinner />
              : renderPanelPhotosById(panel.id)}
            </PhotosContainerStyled>
          </div>
        )
        return { tab: panel.id, text: panel.name, render: TabContent }
      })

    return panelTabs ?
        <Accordion
          title='Panels'
          Content={() =>
            isPdfView ?
              renderPdfPanelsInfo(panelTabs)
            : <TabPane tabs={panelTabs} />
          }
          isExpandedInitially={isPdfView}
        />
      : <Accordion
          title='Panels'
          Content={() => <div>No panels</div>}
          isExpandedInitially={isPdfView}
        />
  }

  const renderStepdownTransformerInfo = React.useCallback(() => {
    const { powerSource } = meterInstallationDetails || {}
    const { stepDownTransformer } = powerSource || {}
    const transformerInfo = {
      'Make ': stepDownTransformer?.name || '-',
      'Model ': stepDownTransformer?.model,
      ...(stepDownTransformer?.stepDownFrom ?
        { 'Step-Down From (Voltage)': `${stepDownTransformer.stepDownFrom} V` }
      : {}),
      ...(stepDownTransformer?.stepDownTo ?
        { 'Step-Down To (Voltage)': `${stepDownTransformer.stepDownTo} V` }
      : {}),
    }
    return (
      <Accordion
        title='Step-Down Transformer'
        Content={() => renderInfoSection(transformerInfo)}
        isExpandedInitially
      />
    )
  }, [meterInstallationDetails, panelsById])

  const renderMeterPowerSourceInfo = React.useCallback(() => {
    const { powerSource } = meterInstallationDetails || {}
    const powerSourceInfo = {
      'Panel Name': panelsById?.[powerSource.panelId]?.name || '-',
      'Supply Voltage': powerSource?.supplyLineVoltage,
      'Voltage Tap L1':
        powerSource?.vtapL1PhaseA ? `${powerSource?.vtapL1PhaseA}` : '-',
      'Voltage Tap L2':
        powerSource?.vtapL2PhaseB ? `${powerSource?.vtapL2PhaseB}` : '-',
      'Voltage Tap L3':
        powerSource?.vtapL3PhaseC ? `${powerSource?.vtapL3PhaseC}` : '-',
      ...(powerSource?.highLeg ?
        { 'High Leg': `Voltage Tap ${powerSource?.highLeg}` }
      : {}),
      ...(powerSource?.highLegVoltage ?
        { 'High Leg Voltage': `${powerSource?.highLegVoltage} V` }
      : {}),
      ...(powerSource?.preStepDownPhaseAtoNeutralVoltage ?
        {
          'Phase A line-to-neutral voltage at the panel (before the step-down transformer)': `${powerSource?.preStepDownPhaseAtoNeutralVoltage} V`,
        }
      : {}),
      ...(powerSource?.postStepDownPhaseAtoNeutralVoltage ?
        {
          'Phase A line-to-neutral voltage at the meter line input (after the step-down transformer)': `${powerSource?.postStepDownPhaseAtoNeutralVoltage} V`,
        }
      : {}),
      ...(powerSource?.preStepDownPhaseALineToLineVoltage ?
        {
          'Phase A line-to-ground voltage at the panel (before the step-down transformer)': `${powerSource?.preStepDownPhaseALineToLineVoltage} V`,
        }
      : {}),
      ...(powerSource?.postStepDownPhaseALineToLineVoltage ?
        {
          'Phase A line-to-ground voltage at the meter line input (after the step-down transformer)': `${powerSource?.postStepDownPhaseALineToLineVoltage} V`,
        }
      : {}),
      ...(powerSource?.preStepDownPhaseBLineToLineVoltage ?
        {
          'Phase B line-to-ground voltage at the panel (before the step-down transformer)': `${powerSource?.preStepDownPhaseBLineToLineVoltage} V`,
        }
      : {}),
      ...(powerSource?.postStepDownPhaseBLineToLineVoltage ?
        {
          'Phase B line-to-ground voltage at the meter line input (after the step-down transformer)': `${powerSource?.postStepDownPhaseBLineToLineVoltage} V`,
        }
      : {}),
      'Description / Notes': powerSource?.describePowerSource,
    }
    return (
      <Accordion
        title='Meter Power Source'
        Content={() => renderInfoSection(powerSourceInfo)}
        isExpandedInitially
      />
    )
  }, [meterInstallationDetails, panelsById])

  const renderCellRouterInfo = React.useCallback(() => {
    const { cellRouter } = meterInstallationDetails || {}
    const CellRouterSection = () => (
      <InfoContainer>
        <EntryStyled key='Cell Router Installed'>
          <EntryKeyStyled>Cell Router Installed</EntryKeyStyled>
          <EntryValueStyled>
            {cellRouter && !!Object.keys(cellRouter).length ? 'YES' : 'NO'}
          </EntryValueStyled>
        </EntryStyled>
        <EntryStyled key='Cell Router MAC Address'>
          <EntryKeyStyled>Cell Router MAC Address</EntryKeyStyled>
          <EntryValueStyled>{cellRouter?.macAddress || '-'}</EntryValueStyled>
        </EntryStyled>
        <EntryStyled
          className='cell-router-photo'
          key='Cell Router Install Photo'
        >
          <EntryKeyStyled>Cell Router Install Photo</EntryKeyStyled>
          <CellRouterPhotosContainerStyled>
            {renderPhotosInSection('ROUTER')}
          </CellRouterPhotosContainerStyled>
        </EntryStyled>
        <EntryStyled key='Cell Router Install Notes'>
          <EntryKeyStyled>Cell Router Install Notes</EntryKeyStyled>
          <EntryValueStyled>
            {cellRouter?.powerDescription || '-'}
          </EntryValueStyled>
        </EntryStyled>
      </InfoContainer>
    )
    return (
      <Accordion
        title='Cell Router'
        Content={CellRouterSection}
        isExpandedInitially={isPdfView}
      />
    )
  }, [meterInstallationDetails, renderPhotosInSection, isPdfView])

  const renderTestResultsInfo = React.useCallback(() => {
    const { testResults } = meterInstallationDetails || {}
    const networkConnectionInfo = {
      'Last Measurement:': testResults?.netWorkConnectionTest.lastMeasurement,
    }
    const signalStrengthInfo = {
      'Latency:': testResults?.signalStrengthTest.latency,
      'Strength:': testResults?.signalStrengthTest.strength,
      'Quality:': testResults?.signalStrengthTest.quality,
    }

    const troubleshootListNotes = (resp) => ({
      'Troubleshooting:': resp?.troubleshootList?.map((item: string) => (
        <ListItem>
          <span>{item}</span>
        </ListItem>
      )),
    })
    const TestResults = () => (
      <InfoContainer>
        <EntryStyled>
          <EntryKeyStyled>Network Connection:</EntryKeyStyled>
          <EntryValueStyled
            danger={!testResults?.netWorkConnectionTest.connectivityTestPass}
          >
            {testResults?.netWorkConnectionTest.connectivityTestPass ?
              'Pass'
            : 'Failed'}
          </EntryValueStyled>
        </EntryStyled>
        {!testResults?.netWorkConnectionTest.connectivityTestPass && (
          <>
            {renderInfoSection(networkConnectionInfo, true)}
            {renderInfoSection(
              troubleshootListNotes(testResults?.netWorkConnectionTest),
              true,
            )}
          </>
        )}
        <EntryStyled>
          <EntryKeyStyled>Signal Strength Test:</EntryKeyStyled>
          <EntryValueStyled
            danger={!testResults?.signalStrengthTest.signalStrengthTestPass}
          >
            {testResults?.signalStrengthTest.signalStrengthTestPass ?
              'Pass'
            : 'Failed'}
          </EntryValueStyled>
        </EntryStyled>
        {!testResults?.signalStrengthTest.signalStrengthTestPass &&
          renderInfoSection(signalStrengthInfo, true)}
      </InfoContainer>
    )
    return (
      <Accordion
        title='Pre-Config Tests'
        Content={TestResults}
        isExpandedInitially={isPdfView}
      />
    )
  }, [meterInstallationDetails, isPdfView])

  const renderPostTestResultsInfo = React.useCallback(() => {
    const {
      lowAndHighCurrentTestResponse,
      powerConnectionTestResponse,
      powerFactorTestResponse,
    } = postConfigTestResults || {}
    const powerConnectionTest = {
      'Port - Breaker': <EntryKeyStyled>Tested Value</EntryKeyStyled>,
    }
    const acceptableRange = {
      'Acceptable Range:': powerConnectionTestResponse?.acceptedValueRange,
    }
    const powerFactorTest = {
      'Port - Breaker': <EntryKeyStyled>Tested Value</EntryKeyStyled>,
    }
    const lowHighCurrent = {
      'Port - Breaker': <EntryKeyStyled>Tested Value</EntryKeyStyled>,
    }

    const troubleshootListNotes = (resp) => ({
      'Troubleshooting:': resp?.troubleshootList.map((item: string) => (
        <ListItem>
          <span>{item}</span>
        </ListItem>
      )),
      'Note:': resp?.message,
    })

    const TestResults = () => (
      <InfoContainer>
        <EntryStyled>
          <EntryKeyStyled>Power Connection Test:</EntryKeyStyled>
          <EntryValueStyled danger={!powerConnectionTestResponse?.testPass}>
            {powerConnectionTestResponse?.testPass ? 'Pass' : 'Failed'}
          </EntryValueStyled>
        </EntryStyled>
        {!powerConnectionTestResponse?.testPass && (
          <>
            {renderInfoSection(acceptableRange, true)}
            {(
              Object.keys(
                powerConnectionTestResponse?.failureChannelsWithValues,
              ).length >= 10 && !isPdfView
            ) ?
              <StyledGrid>
                {renderInfoSection(powerConnectionTest, true)}
                {renderInfoSection(powerConnectionTest, true)}
              </StyledGrid>
            : renderInfoSection(powerConnectionTest, true)}
            {renderBreakerValueSection(
              appendColonToKey(
                powerConnectionTestResponse?.failureChannelsWithValues,
              ),
              true,
            )}
            {renderInfoSection(
              troubleshootListNotes(powerConnectionTestResponse),
              true,
            )}
          </>
        )}
        <EntryStyled>
          <EntryKeyStyled>Negative Power Factor Test:</EntryKeyStyled>
          <EntryValueStyled danger={!powerFactorTestResponse?.testPass}>
            {powerFactorTestResponse?.testPass ? 'Pass' : 'Failed'}
          </EntryValueStyled>
        </EntryStyled>
        {!powerFactorTestResponse?.testPass && (
          <>
            {(
              Object.keys(powerFactorTestResponse?.failureChannelsWithValues)
                .length >= 10 && !isPdfView
            ) ?
              <StyledGrid>
                {renderInfoSection(powerFactorTest, true)}
                {renderInfoSection(powerFactorTest, true)}
              </StyledGrid>
            : renderInfoSection(powerFactorTest, true)}
            {renderBreakerValueSection(
              appendColonToKey(
                powerFactorTestResponse?.failureChannelsWithValues,
              ),
              true,
            )}
            {renderInfoSection(
              troubleshootListNotes(powerFactorTestResponse),
              true,
            )}
          </>
        )}
        <EntryStyled>
          <EntryKeyStyled>Low & High Current Test:</EntryKeyStyled>
          <EntryValueStyled danger={!lowAndHighCurrentTestResponse?.testPass}>
            {lowAndHighCurrentTestResponse?.testPass ? 'Pass' : 'Failed'}
          </EntryValueStyled>
        </EntryStyled>
        {!lowAndHighCurrentTestResponse?.testPass && (
          <>
            {(
              Object.keys(
                lowAndHighCurrentTestResponse?.failureChannelsWithValues,
              ).length >= 10 && !isPdfView
            ) ?
              <StyledGrid>
                {renderInfoSection(lowHighCurrent, true)}
                {renderInfoSection(lowHighCurrent, true)}
              </StyledGrid>
            : renderInfoSection(lowHighCurrent, true)}
            {renderBreakerValueSection(
              appendColonToKey(
                lowAndHighCurrentTestResponse?.failureChannelsWithValues,
              ),
              true,
            )}
            {renderInfoSection(
              troubleshootListNotes(lowAndHighCurrentTestResponse),
              true,
            )}
          </>
        )}
      </InfoContainer>
    )

    return (
      <Accordion
        title='Post-Config Tests'
        Content={TestResults}
        isExpandedInitially={isPdfView}
      />
    )
  }, [postConfigTestResults, isPdfView])

  const renderLeftOverEquipmentInfo = React.useCallback(() => {
    const { wrapUp } = meterInstallationDetails || {}
    const leftOverEquipmentInfo = {
      'Excess Meter Quantity': wrapUp?.excessMeterQuantity,
      'Excess Cell Router Quantity': wrapUp?.excessCellRouterQuantity,
      'Excess CT Harness Quantity': wrapUp?.excessCtHarnessQuantity,
      'Excess Wire Tap Quantity': wrapUp?.excessWireTapQuantity,
      'Install Notes': wrapUp?.notes,
    }
    return (
      <Accordion
        title='Left Over Equipment'
        Content={() =>
          renderInfoSectionAndPhotos(
            leftOverEquipmentInfo,
            'LEFT_OVER_EQUIPMENT',
          )
        }
        isExpandedInitially={isPdfView}
      />
    )
  }, [meterInstallationDetails, renderPhotosInSection, isPdfView])

  const renderInstallerContactInfo = React.useCallback(() => {
    const installerContactInfo = {
      'First Name': installerDetails?.firstName,
      'Last Name': installerDetails?.lastName,
      'Email Address': installerDetails?.email,
      Phone: installerDetails?.phone,
      Company: installerDetails?.company,
    }
    return (
      <Accordion
        title='Installer Contact Info'
        Content={() => renderInfoSection(installerContactInfo)}
        isExpandedInitially={isPdfView}
      />
    )
  }, [installerDetails, isPdfView])

  const [loading, setLoading] = useState(false)
  const [fetchImgError, setFetchImageError] = useState('')

  const handleDownloadImages = () => {
    setLoading(true)
    const zip = new JSZip()
    // Create promises for each image download
    const downloadPromises = photos.map(async (photo, index) => {
      const imgURL = photo?.preSignedUrl
      const splitData = imgURL?.split('.')
      const lastIndex = splitData[splitData?.length - 1]
      const ext = lastIndex?.split('?')[0]
      return fetch(imgURL)
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok')
          }
          return response.blob()
        })
        .then((blob) => {
          // Add the blob to the zip file
          zip.file(`Image_${index + 1}.${ext}`, blob)
        })
        .catch((error) => {
          setFetchImageError(error)
        })
    })
    // After all promises are resolved, create and save the zip file
    Promise.all(downloadPromises).then(() => {
      zip
        .generateAsync({ type: 'blob' })
        .then((zipBlob) => {
          // Save the zip file
          saveAs(zipBlob, 'Images.zip')
          setLoading(false)
        })
        .catch((error) => {
          setZipError(error)
          setLoading(false)
        })
    })
  }

  function html2pdfAsync(content) {
    return new Promise((resolve, reject) => {
      try {
        const opt = {
          margin: 5,
          html2canvas: { scale: 1 },
          filename: 'meter-submission-export.pdf',
          pagebreak: { avoid: ['.rt-tr', 'img', '.row-break'], after: '.pb' },
        }
        html2pdf()
          .set(opt)
          .from(content)
          .save()
          .then(() => resolve())
      } catch (error) {
        reject(error)
      }
    })
  }

  const handlePdfExport = async () => {
    if (!isAllImageDataURILoaded() || isPdfView) return
    setIsPdfView(true)
    await new Promise((resolve) => setTimeout(resolve, 1000))
    const content = exportContentRef.current
    await html2pdfAsync(content)
    setIsPdfView(false)
  }

  const renderExportPdfButtonText = () => {
    let text = ''
    let className = ''

    if (!isAllImageDataURILoaded()) {
      text = 'Preparing PDF'
      className = 'disabled'
    } else if (isPdfView) {
      text = 'Exporting PDF'
      className = 'disabled'
    } else {
      text = 'Export PDF'
    }

    return (
      <>
        {(!isAllImageDataURILoaded() || isPdfView) && <Spinner size='micro' />}{' '}
        <span className={className}>{text}</span>
      </>
    )
  }

  const renderMainContainerContent = (forPdfView?: boolean) => (
    <>
      {!forPdfView && (
        <BreadcrumbsStyled>
          <Breadcrumbs items={breadcrumbs} />
        </BreadcrumbsStyled>
      )}
      <ContainerStyled>
        <div>
          <TitleStyled>Meter Install Submission</TitleStyled>

          {!forPdfView && (
            <>
              <ErrorStyled>{zipError}</ErrorStyled>
              <ErrorStyled>{fetchImgError}</ErrorStyled>
            </>
          )}
        </div>
        {!forPdfView && (
          <ButtonGroupStyled>
            {photos.length > 0 && (
              <ExportButtonStyled
                loading={loading}
                onClick={!loading ? handleDownloadImages : () => {}}
              >
                {loading && <Spinner size='micro' />}
                <span>Download Images</span>
              </ExportButtonStyled>
            )}

            <ExportButtonStyled
              loading={!isAllImageDataURILoaded() || isPdfView}
              onClick={() => handlePdfExport()}
            >
              {renderExportPdfButtonText()}
            </ExportButtonStyled>
          </ButtonGroupStyled>
        )}
      </ContainerStyled>
      {details && !Object.keys(details).length && !!detailsLoading ?
        <Spinner />
      : <>
          {renderMeterInfo()}
          {renderMeterPowerSourceInfo()}
          <div className='pb' />
          {powerSource?.stepDownTransformer && renderStepdownTransformerInfo()}
          {renderInstallLocationInfo()}
          <div className='pb' />
          {renderPanelsInfo()}
          <div className='pb' />
          {renderFinishedInstallPhotos()}
          <div className='pb' />
          {renderPanelSchedulePhotos()}
          <div className='pb' />
          {renderLockoutTagoutPhotos()}
          <div className='pb' />
          {renderCellRouterInfo()}
          <div className='pb' />
          {renderTestResultsInfo()}
          {postConfigTestResults && (
            <>
              <div className='pb' />
              {renderPostTestResultsInfo()}
            </>
          )}
          <div className='pb' />
          {renderLeftOverEquipmentInfo()}
          <div className='pb' />
          {renderInstallerContactInfo()}
        </>
      }
    </>
  )

  const finalRender = () => (
    <>
      <MainContainer>{renderMainContainerContent()}</MainContainer>
      <MainContainer
        ref={exportContentRef}
        className={isPdfView ? 'pdfview' : 'hidden'}
      >
        {renderMainContainerContent(true)}
      </MainContainer>
    </>
  )
  return finalRender()
}

const mapDispatchToProps = (dispatch) => ({
  actions: {
    ...bindActionCreators(meterInstallSubmissionDetailsActions, dispatch),
  },
})

const mapStateToProps = (state) => {
  const meterDetailsEntity = selectMeterInstallSubmissionDetailsEntity(state)
  const { detailsLoading } = meterDetailsEntity.detailsMeta
  const { photosLoading } = meterDetailsEntity.photosMeta
  return {
    details: meterDetailsEntity.details,
    photos: meterDetailsEntity.photos,
    detailsLoading,
    photosLoading,
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MeterInstallSubmissionDetails),
)
