import Tippy from '@tippyjs/react'
import moment from 'moment'
import { useCallback, useMemo } from 'react'

import {
  breadcrumbs,
  defaultMeterInstallHeaders,
  defaultMeterInstallWidths,
  mockData as submissions,
} from './constants'
import MeterTable from './MeterTable'
import {
  ArrowUpStyles,
  BreadcrumbsStyles,
  Cell,
  JobStatusStyles,
  MainStyles,
  OpportunityStyles,
  TableStyles,
  TitleStyles,
} from './styles'
import Breadcrumbs from '../../components/Breadcrumbs'
import ChevronDown from '../../components/Icons/svg/ChevronDown'
import ChevronUp from '../../components/Icons/svg/ChevronUp'
import RedaptiveReactTable7 from '../../components/RedaptiveReactTable7'
import TableHead from '../../components/RedaptiveReactTable7/TableHead'

export default function MeterInstallGroupedSubmissions() {
  const tableHeaderRows = useMemo(() => {
    const headerRows = defaultMeterInstallHeaders.map((item) => ({
      id: item.id,
      label: item.label,
      sortable: item.id === 'opportunityId' || item.id === 'jobCompletionDate',
    }))
    return [
      {
        id: 'row1',
        headers: headerRows,
      },
    ]
  }, [])

  const TableHeadComponent = useCallback(
    () => <TableHead rows={tableHeaderRows} />,
    [tableHeaderRows],
  )

  const getColumnCell = useCallback((cellProps) => {
    const {
      row,
      column: { id },
      value,
    } = cellProps
    switch (id) {
      case 'opportunityId':
        return (
          <OpportunityStyles {...row.getToggleRowExpandedProps()}>
            {value}
            <ArrowUpStyles>
              {!row.isExpanded ?
                <ChevronDown />
              : <ChevronUp />}
            </ArrowUpStyles>
          </OpportunityStyles>
        )
      case 'jobStatus':
        return (
          <JobStatusStyles inProgress={value === 'In Progress'}>
            {value}
          </JobStatusStyles>
        )
      case 'jobCompletionDate':
        return `${moment(value).format('MM/DD/YYYY, hh:mm:ss A')} UTC`
    }
    return (
      (
        <Tippy content={value}>
          <Cell maxWidth={defaultMeterInstallWidths[id]?.maxWidth}>
            {value}
          </Cell>
        </Tippy>
      ) || '-'
    )
  }, [])

  const columns = useMemo(
    () =>
      defaultMeterInstallHeaders.map((item) => ({
        accessor: item.id,
        Cell: getColumnCell,
        Header: item.label,
        minWidth: defaultMeterInstallWidths[item.id]?.minWidth,
        maxWidth: defaultMeterInstallWidths[item.id]?.maxWidth,
      })),
    [getColumnCell],
  )

  const subTable = () => <MeterTable />

  return (
    <div>
      <MainStyles>
        <BreadcrumbsStyles>
          <Breadcrumbs items={breadcrumbs} />
        </BreadcrumbsStyles>
        <TitleStyles>Meter Install Grouped Submissions</TitleStyles>
        <TableStyles>
          <RedaptiveReactTable7
            columns={columns}
            data={submissions}
            TableHead={TableHeadComponent}
            renderRowSubComponent={subTable}
            filterable
            globalFilterable={false}
            showTableScrollArrows={false}
          />
        </TableStyles>
      </MainStyles>
    </div>
  )
}
