import Big from 'big.js'
import { capitalCase } from 'capital-case'
import moment from 'moment'
import makeNatSort from 'natsort'
import { UsaStates } from 'usa-states'

import docCookies from './docCookies'
import type { FTItem } from '../components/ListSelector'
import * as requestStatusStates from '../constants/status'
import type { FTFormFieldEvent } from '../types'

export const positiveRange = (start, end) =>
  start > -1 && end > -1 ?
    Array.from(
      {
        length: end - start,
      },
      (v, k) => k + start,
    )
  : []
export const makeLetterRange = (startLetter = 'a', endLetter = 'b') => {
  if (!startLetter || !endLetter) {
    return []
  }

  const startCode = startLetter.toUpperCase().charCodeAt()
  const endCode = endLetter.toUpperCase().charCodeAt()

  // Verify that range is between ASCII A and Z
  if (startCode < 65 || endCode > 90) {
    return []
  }

  return positiveRange(startCode, endCode + 1).map((code) =>
    String.fromCharCode(code),
  )
}
export const makeAlphanumericRange = (start, end, startLetter, endLetter) => {
  const numbers = positiveRange(start, end + 1)
  const letters = makeLetterRange(startLetter, endLetter)
  return [].concat(
    ...numbers.map((n) => [`${n}`, ...letters.map((l) => `${n}${l}`)]),
  )
}
// getOddEvenSplitNumber returns the number based on current index and length of
// an **even length list**. It is used in the context of iterating a list.
// By passing the current index, `i`, and the length of the list, it can be used to
// generate a list which has all of the odd indices followed by all of the even ones.
// e.g.: A list of length 10 generates: [1, 3, 5, 7, 9, 2, 4, 6, 8, 10]
export const getOddEvenSplitNumber = (i, len) => {
  // One-liner: (2 * i % len) + Math.floor(i / (len / 2)) + 1
  const half = len / 2
  // Offset is 1 for the first half of the list, 2 for the second half.
  const offset = Math.floor(i / half) + 1
  return ((2 * i) % len) + offset
}
export const getDaysInMonth = (month, year = 2018) =>
  month ?
    positiveRange(
      1,
      moment(`${month} ${year}`, 'MMMM YYYY').daysInMonth() + 1,
    ).map((d) => ({
      id: d,
      name: `${d}`,
    }))
  : []
export const makeListSelectorItem = (
  value: string | number,
  name: string = '',
) => {
  if (!value) {
    return null
  }

  const nameValue = name || `${value}`
  return {
    id: value,
    name: nameValue,
  }
}
export const valueOrDefault = (value, defaultValue = '') =>
  value || defaultValue
export const isValueSet = (value: any) =>
  value !== null && value !== undefined && value !== ''
export const valueSetOrDefault = (value, defaultValue = '') =>
  isValueSet(value) ? value : defaultValue
const { states } = new UsaStates()
export const US_STATES = states.map(({ abbreviation }) =>
  makeListSelectorItem(abbreviation),
)
// Source: https://github.com/vlucas/devdata.io/blob/master/datasets/canadian-provinces.json
export const CANADIAN_PROVINCES = [
  'AB', // Alberta
  'BC', // British Columbia
  'MB', // Manitoba
  'NB', // New Brunswick
  'NL', // Newfoundland and Labrador
  'NS', // Nova Scotia
  'NT', // Northwest Territories
  'NU', // Nunavut
  'ON', // Ontario
  'PE', // Prince Edward Island
  'QC', // Québec
  'SK', // Saskatchewan
  'YT', // Yukon Territories
].map((region) => makeListSelectorItem(region))
const COUNTRY_REGIONS = {
  us: US_STATES,
  ca: CANADIAN_PROVINCES,
}
const SUPPORTED_COUNTRIES = Object.keys(COUNTRY_REGIONS)
type FTSupportedCountries = 'us' | 'ca'
export const getRegionsForCountry = (country: FTSupportedCountries) => {
  const countryLower = country.toLowerCase()

  if (!country || !SUPPORTED_COUNTRIES.includes(countryLower)) {
    throw new Error('Country is not supported')
  }

  return COUNTRY_REGIONS[countryLower]
}
export const MONTHS = moment.months().map((m, i) => ({
  id: i + 1,
  name: m,
}))
export const YEARS_TO_DATE = positiveRange(2014, moment().add(1, 'year').year())
  .map((y) => ({
    id: y,
    name: `${y}`,
  }))
  .reverse()
export const YES_NO = [
  {
    name: 'Yes',
    id: 'yes',
  },
  {
    name: 'No',
    id: 'no',
  },
]
export { normalizeMACAddress, validateMACAddress } from './macAddress'
export const formatHealthStatus = (healthStatus: number | null | undefined) =>
  isValueSet(healthStatus) ?
    `${Math.floor(healthStatus * 100)}%`
  : 'Unavailable'
export const renderHealthStatus = (requestStatus, healthStatus) => {
  switch (requestStatus) {
    case requestStatusStates.ERROR:
      return 'Unavailable'

    case requestStatusStates.LOADED:
      return formatHealthStatus(healthStatus)

    default:
      return 'Loading...'
  }
}

/**
 * Configures natural sorting to be case-insensitive.
 */
const natSort = makeNatSort({
  insensitive: true,
})

/**
 * Prepares string for sorting.
 *
 * - Ensures that whitespace is trimmed.
 * - Ensures spaces are sorted first.
 */
const prepareSortString = (sortString: string) =>
  sortString // Removes leading spaces.
    .trim() // Puts spaces first.
    // This makes "7 b" come before "7a"
    .replace(' ', '-')

/**
 * Sorts naturally.
 */
export const naturallySort = (a: string, b: string) =>
  natSort(prepareSortString(a), prepareSortString(b))

/**
 * Sorts with null, undefined, or an empty string at the bottom.
 */
export const naturallySortEmptyLast = (
  a: string,
  b: string,
  desc?: boolean,
) => {
  const nullReplacement = desc ? '' : 'zzzzzzz'

  const getAdjustedValue = (value) =>
    value === null || value === undefined || value === '' ?
      nullReplacement
    : value

  return natSort(getAdjustedValue(a), getAdjustedValue(b))
}

/**
 * Sorts with null, undefined, or an empty string at the bottom.
 */
export const naturallySortEmptyLastCaseInsensitive = (
  a: string,
  b: string,
  desc?: boolean,
) => {
  const nullReplacement = desc ? '' : 'zzzzzzz'

  const getAdjustedValue = (value) => {
    const adjustedForEmpty =
      value === null || value === undefined || value === '' ?
        nullReplacement
      : value
    return typeof adjustedForEmpty === 'string' ?
        adjustedForEmpty.toUpperCase()
      : value
  }

  return natSort(getAdjustedValue(a), getAdjustedValue(b))
}

/**
 * Sorts items naturally by their name property.
 */
export const naturallySortItems = (a: FTItem, b: FTItem) =>
  naturallySort(a.name, b.name)

/**
 * Determines if a variant is active based on the URL and cookie.
 *
 * To activate a variant, include a comma-separated list in the 'variants' URL
 * query parameter or in the 'variants' cookie.
 *
 * @param {string} variantId
 *   The ID of the variant to check.
 *
 * @return boolean
 *   True if the variant is active, false otherwise.
 */
export const isVariantActive = (variantId: string): boolean => {
  const urlParams = new URLSearchParams(window.location.search)
  const urlVariantsRaw = urlParams.get('variants') || ''

  if (urlVariantsRaw) {
    const expireDate = moment().add(24, 'hours').toDate()
    docCookies.setItem('variants', urlVariantsRaw, expireDate, '/')
  }

  const urlVariants = urlVariantsRaw.split(',')

  if (urlVariants.includes(variantId)) {
    return true
  }

  const cookieVariantsRaw = docCookies.getItem('variants') || ''
  const cookieVariants = cookieVariantsRaw.split(',')
  return cookieVariants.includes(variantId)
}

/**
 * Truncates a number to a particular number of decimal places.
 *
 * Usage:
 *   - var a = 5.467;
 *   - var truncated = truncateDecimals(a, 2); // = 5.46
 * Negative digits:
 *   - var b = 4235.24;
 *   - var truncated = truncateDecimals(b, -2); // = 4200
 *
 * @see https://stackoverflow.com/questions/4912788/truncate-not-round-off-decimal-numbers-in-javascript
 *
 * @param {number} num
 *   Any number.
 * @param {number} digits
 *   The number of digits to display after the decimal.
 *
 * @return {number}
 *  The truncated number.
 */
export const truncateDecimals = (num: number, digits: number): number => {
  const numS = num.toString()
  const decPos = numS.indexOf('.')
  const substrLength = decPos === -1 ? numS.length : 1 + decPos + digits
  const trimmedResult = numS.substr(0, substrLength)
  const finalResult = typeof trimmedResult === 'undefined' ? 0 : trimmedResult
  return parseFloat(finalResult)
}
export const formatNumberToDecimals = (
  num: number,
  digits: number | null | undefined = 3,
): string => Number(truncateDecimals(num, digits)).toFixed(digits)
export const getItemsByIdFromItems = (items: Array<FTItem>): any =>
  items.reduce((acc, cur) => ({ ...acc, [cur.id]: cur }), {})
export const formatNumber = (value: number) =>
  value.toLocaleString('en-US', {
    maximumFractionDigits: 20,
  })
export const scrollTop = () => {
  window.scrollTo(0, 0)
}
export const USD_CURRENCY = 'USD'
export const getCurrencyFormat = (
  locale: string,
  currencyCode: string,
  precision?: number,
): any =>
  new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyCode,
    maximumFractionDigits: isValueSet(precision) ? precision : 2,
  })
export const getNumberFormatMaxFractionDigits2 = (locale: string) =>
  new Intl.NumberFormat(locale, {
    maximumFractionDigits: 2,
  })
export const getNumberFormatMinFractionDigits2 = (locale: string) =>
  new Intl.NumberFormat(locale, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 10,
  })
export const getNumberFormatFractionDigits4 = (locale: string) =>
  new Intl.NumberFormat(locale, {
    minimumFractionDigits: 4,
    maximumFractionDigits: 4,
  })
export const enumToCapitalCase = (value: string | null | undefined = '') =>
  capitalCase(value).replace(/-_/g, ' ')
export const stringGen = (len) => {
  let text = ''
  const charset = 'abcdefghijklmnopqrstuvwxyz0123456789'

  for (let i = 0; i < len; i += 1) {
    text += charset.charAt(Math.floor(Math.random() * charset.length))
  }

  return text
}
export const isElementWidthOverflowing = ({
  clientWidth,
  scrollWidth,
}: HTMLElement): boolean => scrollWidth > clientWidth
export const getElementScrollXPosition = (
  element: HTMLElement | null | undefined,
): {
  left: number
  right: number
} => {
  if (!element) {
    return {
      left: 0,
      right: 0,
    }
  }

  const { clientWidth, scrollLeft, scrollWidth } = element
  return {
    left: scrollLeft,
    right: Math.floor(scrollWidth - scrollLeft - clientWidth),
  }
}
export const zIndices: Record<string, string> = {
  GooglePlacesSearchPlacesResult: 100,
  HeaderContainer: 400,
  Messages: 501,
  ModalConfirm2: 301,
  SalesTaxConfirmModal: 301,
  ModalScenario: 401,
  ConfirmModal2: 301,
  RedaptiveReactTableActionsPopup: 1,
  RedaptiveReactTableColumnSettingsList: 300,
  RedaptiveReactTableThLocked: 1,
  RedaptiveReactTableActiveRow: 3,
  ScenarioFormStickyHeader: 1,
  CommentsPopup: 101,
}

/**
 * Present Value.
 *
 * See https://support.microsoft.com/en-us/office/pv-function-23879d31-0e02-4321-be01-da16e8168cbd.
 *
 * If rate is 0 then
 * (pmt * nper) + pv + fv = 0
 * pv = -(pmt * nper) - fv
 * else
 * pv * (1 + rate)^nper + pmt(1 + rate * type) * (((1 + rate)^nper - 1) / rate) + fv = 0
 * pv * (1 + rate)^nper = -(pmt(1 + rate * type) * (((1 + rate)^nper - 1) / rate)) - fv
 * pv = -(pmt(1 + rate * type) * (((1 + rate)^nper - 1) / rate)) - fv / (1 + rate)^nper
 * pv = -(part1 * part2) - fv) / part3
 */
export const pv = (
  rate: number | string,
  nper: number | string,
  pmt: number | string,
  fv: number | string = 0,
  type: number | string = 0,
) => {
  if (parseFloat(rate) === 0) {
    return Big(-1).times(pmt).times(nper).minus(fv).toNumber()
  }

  const part1 = Big(rate).times(type).plus(1).times(pmt)
  const part3 = Big(1).plus(rate).pow(nper)
  const part2 = part3.minus(1).div(rate)
  const final = part1.times(part2).times(-1).minus(fv).div(part3)
  return final.toNumber()
}
export const getNumberWithPrecision = (
  value: string | number,
  precision: number,
) => parseFloat(Big(value).toFixed(precision))
export type FTProjectType = 'LIGHTING' | 'HVAC' | 'WATER' | 'SOLAR'
export const getProjectTypeFormatted = (projectType: FTProjectType) => {
  switch (projectType) {
    case 'HVAC':
      return 'HVAC'

    default:
      return capitalCase(projectType)
  }
}
export const getNameFromEMail = (email: string): string => {
  const fullNameArray = email.split('@')[0].split('.')
  return fullNameArray.length > 1 ?
      fullNameArray.reduce(
        (acc, cur) => `${capitalCase(acc)} ${capitalCase(cur)}`,
      )
    : capitalCase(fullNameArray[0])
}
export const getValueFromEvent = (event: FTFormFieldEvent) => {
  let value

  if (event.target) {
    ;({ checked: value } = event.target)
  } else {
    ;({ value } = event)
  }

  return value
}
export const filterEmptyValues = (object) => {
  const keys = Object.keys(object)
  const newObject = {}

  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i]
    const value = object[key]

    if (value !== null && value !== undefined) {
      newObject[key] = value === 'null' || value === '' ? '' : value
    }
  }

  return newObject
}
export const roundToX = (num, decimals) =>
  +`${Math.round(`${num}e${decimals}`)}e-${decimals}`

export const truncateText = (text: string, maxLength: number) =>
  // Truncate the text and add ellipsis if it exceeds the maxLength
  text.length > maxLength ? `${text.substring(0, maxLength - 3)}...` : text
