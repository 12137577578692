export const defaultMeterInstallHeaders = [
  {
    id: 'opportunityId',
    label: 'Opportunity ID',
  },
  {
    id: 'jobStatus',
    label: 'Installer Job Status',
  },
  {
    id: 'jobCompletionDate',
    label: 'Completion Date',
  },
  {
    id: 'customerName',
    label: 'Customer',
  },
  {
    id: 'siteLocation',
    label: 'Location',
  },
  {
    id: 'allMetersEvVerified',
    label: 'All Meters EV Verified',
  },
  {
    id: 'meterCount',
    label: 'Meter Count',
  },
  {
    id: 'allMetersAssigned',
    label: 'All Meters Assigned',
  },
]

export const defaultMeterInstallWidths = {
  opportunityId: {
    minWidth: 120,
    maxWidth: 120,
  },
  jobStatus: {
    minWidth: 235,
    maxWidth: 235,
  },
  customerName: {
    minWidth: 210,
    maxWidth: 210,
  },
  jobCompletionDate: {
    minWidth: 260,
    maxWidth: 260,
  },
  isAssignedToSite: {
    minWidth: 120,
    maxWidth: 130,
  },
  siteLocation: {
    minWidth: 300,
    maxWidth: 300,
  },
  allMetersEvVerified: {
    minWidth: 225,
    maxWidth: 225,
  },
  meterCount: {
    minWidth: 100,
    maxWidth: 100,
  },
  allMetersAssigned: {
    minWidth: 100,
    maxWidth: 120,
  },
}

export const breadcrumbs = [
  {
    href: '/reports',
    text: 'Tools & Reports',
  },
  {
    href: '/reports/meter-install-submissions',
    text: 'Meter Install Submissions',
  },
]

export const mockData = [
  {
    id: 'd040fd17-4078-4d95-9bf5-ded961e1a8f2',
    jobStatus: 'In Progress',
    jobCompletionDate: '2024-10-25T09:23:01.627987',
    allMetersAssigned: 'YES',
    allMetersEvVerified: 'NO',
    customerName: 'Redaptive',
    meterCount: 3,
    siteLocation: '340 Brannan Street, Suite 104, San Francisco, CA, US, 94107',
    cellRouterInstalled: 'NO',
    userId: 'd807ba99-96ba-418c-bab8-481175ed06de',
    opportunityId: 'R00029431',
    code: 'R00029431',
  },
  {
    id: 'd040fd17-4078-4d95-9bf5-ded961e1a8f2',
    jobStatus: 'Complete',
    jobCompletionDate: '2024-10-25T09:23:01.627987',
    allMetersAssigned: 'YES',
    allMetersEvVerified: 'NO',
    customerName: 'Redaptive',
    meterCount: 3,
    siteLocation: '340 Brannan Street, Suite 104, San Francisco, CA, US, 94107',
    cellRouterInstalled: 'NO',
    userId: 'd807ba99-96ba-418c-bab8-481175ed06de',
    opportunityId: 'R00029431',
    code: 'R00029431',
  },
]
