import styled from 'styled-components'

import {
  ReactTableTopSectionStyles,
  ReactTableWrapperStyles,
} from '../../../components/RedaptiveReactTable7'
import { colors } from '../../../utils/themes'
import { TableLinkStyled } from '../../EvDashboard/styles'

export const TableStyles = styled.div`
  && table {
    table-layout: auto;
    border-collapse: collapse;
    border-spacing: 0;
  }
  thead {
  }
  td,
  th {
    background: none !important;
    border: 0.5px solid ${colors.blue14};
    padding-left: 25px;
  }
  td,
  tr {
    background: white !important;
  }
  ${ReactTableWrapperStyles} {
    border-top: none;
  }
  ${ReactTableTopSectionStyles} {
    display: none;
  }
`

export const HighlightedText = styled.span`
  && {
    color: ${colors.blue15};
    font-weight: 600;
  }
`

export const Link = styled(TableLinkStyled)`
  && {
    color: ${colors.blue15};
  }
  font-weight: 600;
`

export const ViewHistory = styled(Link)`
  font-weight: 500;
`

export const Wrapper = styled.div`
  overflow-x: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  max-width: 93vw;
  width: 100vw;
`
