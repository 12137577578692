/* eslint-disable react/no-this-in-sfc */
import Highcharts from 'highcharts'
import Boost from 'highcharts/modules/boost'
import HighchartsReact from 'highcharts-react-official'
import moment from 'moment'
import styled from 'styled-components'

import '../../ducks/circuitsData'
import type { FTCircuitData } from '../../ducks/circuitsData'
import { DATE_FORMAT_TOOLTIP } from '../../ducks/utils'
import { formatNumber } from '../../utils'

Boost(Highcharts)
Highcharts.seriesTypes.line.prototype.drawLegendSymbol =
  Highcharts.seriesTypes.area.prototype.drawLegendSymbol
Highcharts.setOptions({
  lang: {
    resetZoom: '[Reset Zoom]',
    thousandsSep: ',',
  },
})
const WrapperStyled = styled.div`
  .highcharts-legend,
  .highcharts-axis-labels {
    user-select: none;
  }

  .highcharts-legend-item-hidden .highcharts-point {
    fill: #efefef;
  }
`
type FTProps = {
  chartRef: any
  circuitData: FTCircuitData
  loading: boolean
  measurementTypesMap: Record<
    string,
    {
      label: string
      unit: string
    }
  >
  measurementUnits: Record<string, string>
  timezone: string
}

const PlotViewChart = (props: FTProps) => {
  const {
    chartRef,
    circuitData,
    loading,
    measurementTypesMap,
    measurementUnits,
    timezone,
  } = props
  const {
    data,
    min: minRaw,
    missingData,
    missingWeekendData,
    pointInterval,
    weekends,
    xAxisMajorTickInterval,
    xAxisMinorTickInterval,
    startDateTime,
  } = circuitData
  const measurementTypeLabelToUnitMap = {}
  Object.keys(measurementTypesMap).forEach((measurementType) => {
    const { label, unit } = measurementTypesMap[measurementType]
    measurementTypeLabelToUnitMap[label] = unit
  })
  // $FlowFixMe
  const min = Math.min(...Object.values(minRaw))

  Highcharts.dateFormats.Z = (timestamp) =>
    moment(timestamp).tz(timezone).format(DATE_FORMAT_TOOLTIP)

  const missingDataPlotBands = missingData.map((range) => ({
    color: '#fff8c1',
    ...range,
  }))
  const missingWeekendDataPlotBands = missingWeekendData.map((range) => ({
    color: '#ede6b7',
    ...range,
  }))
  const weekendPlotBands = weekends.map((range) => ({
    color: '#eee',
    ...range,
  }))
  const seriesColors = ['#1895d4', '#000']
  const seriesData = Object.keys(data).map((measurementType, i) => ({
    name: measurementTypesMap[measurementType].label,
    data: loading ? [] : data[measurementType],
    pointInterval,
    pointStart: startDateTime,
    color: seriesColors[i % seriesColors.length],
    showInLegend: true,
    type: 'line',
  }))
  const options = {
    boost: {
      useGPUTranslations: true,
    },
    chart: {
      backgroundColor: 'transparent',
      spacingTop: 24,
      spacingRight: 30,
      spacingBottom: 20,
      spacingLeft: 20,
      height: 320,
      panning: {
        enabled: true,
      },
      panKey: 'shift',
      resetZoomButton: {
        position: {
          align: 'right',
          verticalAlign: 'bottom',
          y: 37,
          x: 8,
        },
        theme: {
          fill: 'transparent',
          states: {
            hover: {
              fill: 'transparent',
              stroke: 'transparent',
              style: {
                'font-family': "'Avenir Next', sans-serif",
                'font-size': '12px',
                'text-decoration': 'underline',
              },
            },
          },
          stroke: 'transparent',
          style: {
            color: '#337ab7',
            'font-family': "'Avenir Next', sans-serif",
            'font-size': '12px',
            'text-decoration': 'none',
          },
        },
      },
      style: {
        fontFamily: '"Avenir Next", sans-serif',
        fontWeight: 'normal',
      },
      zoomType: 'xy',
    },
    credits: {
      enabled: false,
    },
    legend: {
      align: 'left',
      verticalAlign: 'bottom',
      itemStyle: {
        fontSize: '11px',
        fontWeight: '400',
        color: '#444854',
        cursor: 'default',
        letterSpacing: '1px',
      },
      itemHiddenStyle: {
        fontSize: '11px',
        fontWeight: '300',
        color: '#444854',
        cursor: 'default',
        letterSpacing: '1px',
      },
      itemHoverStyle: {
        color: '#444854',
      },
      itemDistance: 40,
      margin: 20,
      squareSymbol: false,
      symbolWidth: 14,
      symbolRadius: 2,
    },
    noData: {
      style: {
        fontSize: '13px',
        fontWeight: '500',
        color: '#464a54',
        lineHeight: '1.38',
        userSelect: 'none',
      },
      position: {
        y: -50,
      },
    },
    plotOptions: {
      column: {
        animation: {
          duration: 0,
        },
        borderWidth: 0,
        grouping: false,
        groupPadding: 0,
        negativeColor: 'rgb(240,128,0)',
        pointPadding: -0.5,
        shadow: false,
        states: {
          hover: {
            enabled: false,
          },
        },
      },
      line: {
        animation: false,
        boostThreshold: 0,
        lineWidth: 1,
        marker: {
          radius: 0,
          states: {
            hover: {
              radius: 4,
            },
          },
        },
        states: {
          hover: {
            lineWidthPlus: 0,
          },
        },
        zIndex: 1,
      },
    },
    series: [
      ...seriesData,
      {
        color: '#eee',
        data: [],
        name: 'Weekends',
        type: 'column',
      },
      {
        color: '#fff8c1',
        data: [],
        name: 'Missing Measurements',
        type: 'column',
      },
    ],
    tooltip: {
      outside: false,
      useHTML: true,
      backgroundColor: '#ffffff',
      headerFormat:
        '<div style="' +
        'font-size: 12px;' +
        'margin-bottom: 5px;' +
        '">{point.key}</div>',

      pointFormatter() {
        return (
          '<div><span style="' +
          `background-color: ${this.series.color};` +
          'border-radius: 3px;' +
          'display: inline-block;' +
          'height: 10px;' +
          'margin-right: 2px;' +
          'width: 13px;' +
          '"></span><span style="' +
          'display: inline-block;' +
          'font-size: 12px;' +
          'line-height: 1.6;' +
          '">' +
          `${this.series.name}:
 <b>${formatNumber(this.y)} ${
   measurementTypeLabelToUnitMap[this.series.name]
 }</b>` +
          '</span></div>'
        )
      },

      shared: true,
      xDateFormat: '%Z',
    },
    title: {
      text: '',
    },
    time: {
      moment,
      timezone,
    },
    xAxis: {
      crosshair: true,
      dateTimeLabelFormats: {
        millisecond: '%H:%M:%S.%L',
        second: '%H:%M:%S',
        minute: '%H:%M',
        hour: '%H:%M',
        day: '%b %e',
        week: '%b %e',
        month: "%b '%y",
        year: '%Y',
      },
      labels: {
        style: {
          fontSize: '11px',
        },
      },
      maxPadding: 0,
      minPadding: 0,
      minorTickInterval: xAxisMinorTickInterval,
      minorTicks: true,
      plotBands: [
        ...weekendPlotBands,
        ...missingDataPlotBands,
        ...missingWeekendDataPlotBands,
      ],
      style: {
        fontFamily: '"Avenir Next", sans-serif',
      },
      tickInterval: xAxisMajorTickInterval,
      type: 'datetime',
    },
    yAxis: {
      min,
      title: {
        text: Object.values(measurementUnits).join(', '),
      },
      labels: {
        formatter() {
          return formatNumber(this.value)
        },

        style: {
          fontSize: '11px',
        },
      },
    },
    exporting: {
      enabled: false,
    },
  }
  return (
    <WrapperStyled>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        ref={chartRef}
      />
    </WrapperStyled>
  )
}

export default PlotViewChart
