import React from 'react'
import { Route, Switch } from 'react-router-dom'

import FullEV from './FullEV'
import MeterStatusFormPage from './MeterStatusFormPage'
import ReportsPage from './ReportsPage'
import ManageUtilityDataPage from './UtilityData/ManageUtilityDataPage'
import UtilityCustomerSitesPage from './UtilityData/UtilityCustomerSitesPage'
import UnauthorizedRedirect from '../../authorization/components/UnauthorizedRedirect'
import features from '../../authorization/features'
import type { FTRouterMatch } from '../../types'
import MeterExportFormPage from '../AccountManagement/MeterExportFormPage'
import MeterInstallExportFormPage from '../AccountManagement/MeterInstallExportFormPage'
import ElectronVerifyDashboard from '../EvDashboard'
import MeterInstallGroupedSubmissions from '../MeterInstallGroupedSubmissions'
import MeterInstallSubmissionDetails from '../MeterInstallSubmissions/MeterInstallSubmissionDetails'
import MeterInstallSubmissionsPage from '../MeterInstallSubmissions/MeterInstallSubmissionsPage'
import WebEv from '../WebEv'

type FTProps = {
  match: FTRouterMatch
}

const ToolsAndReports = ({ match: { path, url } }: FTProps) => (
  <div>
    <UnauthorizedRedirect
      feature={features.meterExport}
      requireAll
      from={`${path}/meter-export`}
      to={url}
    />
    <UnauthorizedRedirect
      feature={features.meterInstallReport}
      requireAll
      from={`${path}/meter-install`}
      to={url}
    />
    <UnauthorizedRedirect
      feature={features.meterInstallSubmissions}
      requireAll
      from={`${path}/meter-install-submissions`}
      to={url}
    />
    <UnauthorizedRedirect
      feature={features.manageUtilityData}
      requireAll
      from={`${path}/manage-utility-data`}
      to={url}
    />
    <Switch>
      <Route path={`${path}/meter-status`} component={MeterStatusFormPage} />
      <Route path={`${path}/meter-export`} component={MeterExportFormPage} />
      <Route
        path={`${path}/meter-install`}
        component={MeterInstallExportFormPage}
      />
      <Route
        path={`${path}/meter-install-submissions`}
        exact
        component={MeterInstallSubmissionsPage}
      />
      <Route
        path={`${path}/meter-install-grouped-submissions`}
        exact
        component={MeterInstallGroupedSubmissions}
      />
      <Route
        path={`${path}/manage-utility-data/customer-sites`}
        exact
        component={UtilityCustomerSitesPage}
      />
      <Route
        path={`${path}/manage-utility-data`}
        exact
        component={ManageUtilityDataPage}
      />
      <Route
        path={`${path}/manage-utility-data/*`}
        exact
        component={ManageUtilityDataPage}
      />
      <Route
        path={`${path}/meter-install-submissions/:meterSubmissionId`}
        component={MeterInstallSubmissionDetails}
      />
      <Route exact path={`${path}/webev`} component={FullEV} />
      <Route
        path={`${path}/electron-verify-dashboard`}
        component={ElectronVerifyDashboard}
      />
      <Route
        path={`${path}/webev-history/:evId/macAddress/:macAddress`}
        component={WebEv}
      />
      <Route path={`${path}/webev/:macAddress`} component={WebEv} />
      <Route component={ReportsPage} />
    </Switch>
  </div>
)

export default ToolsAndReports
